.wrapper-likes-page {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);
  .likes-page {
    height: calc(100vh - 220px);
    overflow: auto;
    .header-likes-page {
      display: flex;
      align-items: center;
      padding: 0px 20px;
      .title {
        font-family: "Nunito SemiBold";
        color: $color-white;
        font-size: $size-28;
      }
      .number-likes {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        background-color: $color-black-80;
        height: 40px;
        padding: 0px 16px;
        margin-left: 8px;
        span {
          font-family: "Nunito Regular";
          color: $color-white;
          font-size: $size-18;
        }
      }
    }
    .content-likes-page {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .wrapper-user-liked {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        border-bottom: 1px solid $color-black-80;
        padding: 0px 20px;
        .user-like-info {
          display: flex;
          align-items: center;
          .avatar-user {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 8px;
          }
          span {
            font-family: "Nunito Bold";
            color: $color-white;
            font-size: $size-18;
          }
          .badge {
            filter: brightness(0) saturate(100%) invert(39%) sepia(15%) saturate(1729%) hue-rotate(258deg) brightness(93%) contrast(96%);
            margin-left: 8px;
          }
        }
        .custom-btn {
          width: fit-content;
        }
      }
    }
  }
  &.lists {
    width: 100%;
    margin-left: 0;
  }
}
