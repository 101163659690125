// Box review
.wrapper-box-review {
  display: flex;
  justify-content: space-between;
  border: 1px solid $color-black-70;
  box-shadow: 0px 10px 17.3px 0px #00000024;
  border-radius: 50px;
  height: 74px;
  margin-bottom: 16px;
  padding: 0px 14px;
  background-color: $color-black-88;
  margin-top: 32px;
  .box-review-left {
    display: flex;
    align-items: center;
    .number-rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      background-color: $color-orange-100;
      width: 46px;
      height: 46px;
      object-fit: cover;
      border-radius: 50%;
      flex-basis: 46px;
      flex-shrink: 0;
      span {
        font-family: "Nunito Bold";
        font-size: $size-22;
        color: $color-black;
      }
    }
    .wrapper-stars-box {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin-right: 5px;
      }
    }
  }
  .box-review-right {
    display: flex;
    align-items: center;
    .number-review {
      font-family: "Nunito Bold";
      font-size: $size-24;
      color: $color-white;
      margin-right: 6px;
    }
    .label-review {
      font-family: "Nunito Regular";
      font-size: $size-22;
      color: $color-black-40;
    }
  }
}
// Button create post
.wrapper-btn-create-post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $color-black-70;
  border-radius: 16px;
  padding: 0px 15px;
  margin: 24px 0px;
  height: 88px;
  box-shadow: 0px 10px 17.3px 0px #00000024;
  background-color: $color-black-88;
  cursor: pointer;
  .wrapper-info-author {
    display: flex;
    align-items: center;
    .author-post-image {
      margin-right: 12px;
      display: flex;
      flex-basis: 60px;
      flex-shrink: 0;
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        filter: none;
      }
    }
    .create-review-card-info {
      display: flex;
      flex-direction: column;
      .create-review-stars {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          margin-right: 2px;
          filter: brightness(0) saturate(100%) invert(63%) sepia(0%) saturate(1%) hue-rotate(255deg) brightness(95%) contrast(94%);
        }
      }
    }
    .text-create-post {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black-40;
    }
  }

  img {
    width: 18px;
    filter: brightness(0) saturate(100%) invert(37%) sepia(10%) saturate(2742%) hue-rotate(258deg) brightness(97%) contrast(92%);
  }
}
