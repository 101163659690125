.wrapper-single-list-page {
  padding: 16px;
  padding-bottom: 90px;
  .header-page {
    background-color: $color-black-88;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .wrapper-header-single-list {
    background-color: $color-black-88;
    display: flex;
    justify-content: center;
    .header-single-list {
      max-width: 960px;
      width: 100%;
      .wrapper-info-single-list {
        display: flex;
        align-items: center;
        .wrapper-img-single-list {
          margin-right: 24px;
          img {
            width: 215px;
            height: 175px;
            border-radius: 6px;
            object-fit: cover;
          }
        }
        .info-single-list {
          display: flex;
          align-items: center;
        }
        .title-single-list {
          font-family: "Nunito SemiBold";
          color: $color-white;
          font-size: $size-28;
          margin-top: 8px;
        }
        .wrapper-info-author-list {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .wrapper-image-author-list {
            margin-right: 6px;
            display: flex;
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              object-fit: cover;
              flex-basis: 32px;
              flex-shrink: 0;
            }
          }
          .info-author-list {
            display: flex;
            flex-direction: column;
            .wrapper-username {
              display: flex;
              align-items: center;
              span {
                font-family: "Nunito SemiBold";
                color: $color-white;
                font-size: $size-18;
                margin-right: 6px
              }
              img {
                filter: brightness(0) saturate(100%) invert(38%) sepia(6%) saturate(4769%) hue-rotate(258deg) brightness(93%) contrast(90%);
              }
            }
            span {
              font-family: "Nunito Regular";
              color: $color-black-40;
              font-size: $size-14;
            }
          }
        }
      }
      .wrapper-likes-and-more-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 28px 0px;
        .wrapper-likes {
          display: flex;
          align-items: center;
          .custom-btn {
            margin-right: 8px;
            width: 48px;
            height: 48px;
            display: flex;
            flex-basis: 48px;
            flex-shrink: 0;
            img {
              height: 24px;
            }
          }
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-18;
            color: $color-white;
          }
        }
        .custom-btn {
          width: 48px;
          height: 48px;
          margin-right: 0px;
          img {
            height: 24px;
          }
        }
      }
    }
  }
  .wrapper-content-single-list {
    display: flex;
    justify-content: center;
    .content-single-list {
      max-width: 960px;
      width: 100%;
      .wrapper-tabs-items {
        .header-tab-items {
          display: flex;
          align-items: center;
          margin: 30px 0px;
          .wrapper-custom-search {
            .wrapper-delete-search {
              right: 5px;
              top: 9px;
            }
            input {
              background-color: $color-black-88;
              height: 50px;
            }
          }
          .custom-btn {
            margin-left: 12px;
            &--circle-outline {
              width: 58px;
              height: 50px;
              border-radius: 40px;
              flex-basis: 58px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 17px;
              }
            }
            &--outline {
              width: auto;
              min-width: 175px;
              span {
                text-wrap: nowrap;
              }
            }
          }
          .switch-grid {
            width: 105px;
            min-width: 105px;
            height: 50px;
            border: 1px solid $color-black-70;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: transparent;
            margin-left: 12px;
            .btn-switch-grid {
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin: 0px 10px;
              img {
                filter: brightness(0) saturate(100%) invert(73%) sepia(7%) saturate(0%) hue-rotate(30deg) brightness(97%) contrast(95%);
                height: 20px;
              }
              &:first-child {
                margin-left: 20px;
              }
              &:last-child {
                margin-right: 20px;
              }
              &.active {
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(263deg) brightness(104%) contrast(101%);
                }
              }
            }
          }
        }
      }
      .wrapper-posts-tab {
        .wrapper-card-create-post {
          background-color: $color-black-88;
        }
      }
    }
  }
}
