.wrapper-group-accounts {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow: auto;
  .wrapper-info-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 275px;
    .image-preview {
      margin-bottom: 16px;
      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .wrapper-username {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      span {
        margin-right: 4px;
        font-family: "Nunito Bold";
        font-size: $size-18;
        color: $color-white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }
      img {
        filter: brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(1954%) hue-rotate(258deg) brightness(99%) contrast(87%);
      }
    }
    .user-name {
      font-family: "Nunito Regular";
      font-size: $size-16;
      color: $color-black-40;
      margin-bottom: 16px;
    }
    .custom-btn {
      width: auto;
      min-width: 10px;
    }
  }
  .wrapper-admin-preview {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid $color-black-85;
    .text-admin {
      font-family: "Nunito Bold";
      font-size: $size-20;
      color: $color-black-40;
      display: block;
      margin: 20px 0px;
    }
  }
  .wrapper-users-preview {
    display: flex;
    justify-content: center;
    padding: 24px 150px 150px;
    flex-wrap: wrap;
  }
}
