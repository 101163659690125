.wrapper-custom-rating {
  display: flex;
  align-items: center;
  .text-rating {
    font-family: "Nunito Regular";
    color: $color-black-40;
    font-size: $size-14;
  }
  .wrapper-stars-rating {
    display: flex;
    align-items: center;
    margin: 0px 6px;
  }
}
