.wrapper-posts-tab {
  padding: 20px;
}
.wrapper-card-create-post {
  height: 88px;
  border: 1px solid $color-black-70;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 24px;
  .wrapper-left-card-create-post {
    display: flex;
    align-items: center;
    padding-left: 22px;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
      filter: none;
      padding-right: 0px;
    }
    span {
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-18;
      margin-left: 12px;
    }
  }
  img {
    padding-right: 22px;
    width: 22px;
    filter: brightness(0) saturate(100%) invert(34%) sepia(49%) saturate(589%) hue-rotate(258deg) brightness(96%) contrast(91%);
  }
}
.wrapper-card-post {
  background-color: $color-black-85;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  .header-card-post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-card-post {
      display: flex;
      align-items: center;
      .user-img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 12px;
      }
      .wrapper-user-card-info-post {
        display: flex;
        flex-direction: column;
        .user-name {
          display: flex;
          align-items: center;
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-22;
            color: $color-white;
          }
          img {
            margin-left: 4px;
            width: 14px;
            filter: brightness(0) saturate(100%) invert(35%) sepia(33%) saturate(824%) hue-rotate(258deg) brightness(98%) contrast(92%);
          }
        }
        span {
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-black-40;
        }
      }
    }
    .view-more-post {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: brightness(0) saturate(100%) invert(80%) sepia(4%) saturate(0%) hue-rotate(307deg) brightness(97%) contrast(94%);
      }
    }
  }
  .content-card-post {
    display: flex;
    flex-direction: column;
    margin: 18px 0px;
    .description {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black-20;
      span {
        font-family: "Nunito Bold";
        color: $color-white;
      }
    }
    .wrapper-stars-preview {
      display: flex;
      align-items: center;
      margin-top: 16px;
      img {
        width: 38px;
      }
    }
    .wrapper-image-tagged {
      display: flex;
      align-items: center;
      margin: 18px 0px;
      img {
        height: 270px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        margin: 0px 12px;
        cursor: pointer;
      }
    }
    .wrapper-toolbar-post {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 43px;
      margin: 18px 0px;
      .wrapper-toolbar-left {
        span {
          font-family: "Nunito Regular";
          color: $color-black-40;
          font-size: $size-20;
          cursor: pointer;
        }
      }
      .wrapper-toolbar-right {
        display: flex;
        align-items: center;
        .wrapper-like {
          display: flex;
          align-items: center;
          padding: 0px 12px;
          cursor: pointer;
          margin-left: 16px;
          img {
            width: 24px;
            margin-right: 8px;
            filter: brightness(0) saturate(100%) invert(95%) sepia(81%) saturate(588%) hue-rotate(184deg) brightness(113%) contrast(101%);
          }
          span {
            font-family: "Nunito Regular";
            color: $color-white;
            font-size: $size-20;
          }
          .no-like {
            display: block;
          }
          .like {
            display: none;
          }
          &.liked {
            .no-like {
              display: none;
            }
            .like {
              display: block;
            }
          }
        }
        .wrapper-share {
          display: flex;
          align-items: center;
          padding: 0px 12px;
          cursor: pointer;
          margin-left: 16px;
          img {
            width: 24px;
            margin-right: 8px;
            filter: brightness(0) saturate(100%) invert(95%) sepia(81%) saturate(588%) hue-rotate(184deg) brightness(113%) contrast(101%);
          }
          span {
            font-family: "Nunito Regular";
            color: $color-white;
            font-size: $size-20;
          }
        }
      }
    }
    .wrapper-write-comment {
      display: flex;
      align-items: center;
      padding-top: 18px;
      border-top: 1px solid $color-black-70;
      .avatar-comment {
        display: flex;
        flex-basis: 43px;
        flex-shrink: 0;
        img {
          width: 43px;
          height: 43px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 12px;
        }
      }
      .wrapper-input {
        margin-bottom: 0px;
        width: 100%;
        .custom-input {
          height: 43px;
        }
      }
      .custom-btn {
        &--circle-primary {
          width: 43px;
          height: 43px;
          flex-basis: 43px;
          flex-shrink: 0;
          display: flex;
          margin-left: 12px;
          img {
            width: 22px;
          }
        }
      }
    }
  }
}
