// ========== Margin and padding values array ==========
$space-values : (
        0,
        5,
        10,
        12,
        15,
        20,
        30,
        40,
        50
) !default;

// ========== Margin and padding shorthands ==========
$space-prefixes : (
        p  : padding,
        pt : padding-top,
        pr : padding-right,
        pb : padding-bottom,
        pl : padding-left,
        m  : margin,
        mt : margin-top,
        mr : margin-right,
        mb : margin-bottom,
        ml : margin-left,
) !default;

$grid-breakpoints-custom: (
    // Extra small screen / phone
        xs: 0,
    // Small screen / phone
        sm: 576px,
    // Medium screen / tablet
        md: 768px,
    // Large screen / desktop
        lg: 992px,
    // Extra large screen / wide desktop
        xl: 1200px,
    // Extra extra large screen / wide desktop
        xxl: 1400px
) !default;

$breakpoints : $grid-breakpoints-custom;

// ========== Main function definition ==========
@mixin make-space($values, $prefixes, $breakpoints) {
    @each $breakpoint-name, $breakpoint-value in $breakpoints {
        // if xs value = 0, set it global without media queries
        @if($breakpoint-value == 0) {
            @each $attr-short, $attr-long in $prefixes {
                @each $value in $values {
                    .#{$breakpoint-name}-#{$attr-short}-#{$value} {
                        #{$attr-long}: #{$value}#{'px'} !important;
                    }
                }
            }
        }
        // breakpoint values that not equal to 0
        @else {
            @media screen and (min-width: $breakpoint-value) {
                @each $attr-short, $attr-long in $prefixes {
                    @each $value in $values {
                        .#{$breakpoint-name}-#{$attr-short}-#{$value} {
                            #{$attr-long}: #{$value}#{'px'};
                        }
                    }
                }
            }
        }
    }
}

@include make-space($space-values, $space-prefixes, $breakpoints);
