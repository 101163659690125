.wrapper-list-items {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  .no-item-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    img {
      margin-bottom: 18px;
    }
    span {
      font-family: "Nunito Bold";
      font-size: $size-20;
      color: $color-black-40;
    }
  }
}
.wrapper-item-list-view {
  display: flex;
  align-items: center;
  height: 72px;
  background-color: $color-black-85;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 0px 14px;
  cursor: pointer;
  flex-basis: 72px;
  flex-shrink: 0;
  justify-content: space-between;
  .number {
    margin-right: 12px;
    font-family: "Nunito SemiBold";
    font-size: $size-16;
    color: $color-white;
  }
  .wrapper-list-item-image {
    margin-right: 12px;
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .wrapper-list-item-info {
    display: flex;
    flex-direction: column;
    span.name {
      font-family: "Nunito Bold";
      font-size: $size-18;
      color: $color-white;
    }
    span.info-item {
      font-family: "Nunito Regular";
      font-size: $size-16;
      color: $color-black-50;
    }
  }
}
.wrapper-add-items {
  .wrapper-table-items {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .wrapper-header-table-item {
      height: 38px;
      .wrapper-header-table-row-item {
        display: flex;
        align-items: center;
        .column-table-row-item {
          width: 158px;
          display: flex;
          align-items: center;
          flex-basis: 158px;
          flex-shrink: 0;
          .delete-column {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-black-40;
            margin-left: 4px;
            cursor: pointer;
            img {
              width: 10px;
            }
          }
          span {
            font-family: "Nunito Regular";
            font-size: $size-16;
            color: $color-black-40;
          }
          &:nth-child(1) {
            width: 35px;
            display: flex;
            align-items: center;
            flex-basis: 35px;
            flex-shrink: 0;
          }
          &:nth-child(2) {
            width: 35px;
            display: flex;
            align-items: center;
            flex-basis: 35px;
            flex-shrink: 0;
          }
          &:nth-child(3) {
            width: 70px;
            display: flex;
            align-items: center;
            flex-basis: 70px;
            flex-shrink: 0;
          }
          &:last-child {
            width: 30px;
            display: flex;
            align-items: center;
            flex-basis: 30px;
            flex-shrink: 0;
          }
        }
      }
    }
    .wrapper-content-table-item {
      .wrapper-content-table-row-item {
        display: flex;
        align-items: center;
        height: 67px;
        .column-table-row-item {
          width: 158px;
          display: flex;
          align-items: center;
          flex-basis: 158px;
          flex-shrink: 0;
          height: 67px;
          border-bottom: 1px solid $color-black-80;
          span {
            font-family: "Nunito Bold";
            font-size: $size-16;
            color: $color-white;
          }
          input {
            background-color: transparent;
            border: none;
            outline: none;
            font-family: "Nunito Bold";
            font-size: $size-16;
            color: $color-white;
            &::placeholder {
              font-family: "Nunito Bold";
              font-size: $size-16;
              color: $color-black-40;
            }
            &:focus {
              border: none;
              outline: none;
            }
          }
          .wrapper-image-item-tabel {
            .wrapper-add-photo-item {
              width: 51px;
              height: 51px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $color-black-40;
              cursor: pointer;
              img {
                width: 20px;
              }
            }
            input {
              display: none;
            }
          }
          &:nth-child(1) {
            width: 35px;
            display: flex;
            align-items: center;
            flex-basis: 35px;
            flex-shrink: 0;
            img {
              filter: brightness(0) saturate(100%) invert(62%) sepia(0%) saturate(243%) hue-rotate(67deg) brightness(98%) contrast(94%);
            }
          }
          &:nth-child(2) {
            width: 35px;
            display: flex;
            align-items: center;
            flex-basis: 35px;
            flex-shrink: 0;
            span {
              color: $color-black-40;
              font-family: "Nunito Regular";
            }
          }
          &:nth-child(3) {
            width: 70px;
            display: flex;
            align-items: center;
            flex-basis: 70px;
            flex-shrink: 0;
            .wrapper-photo-item {
              width: 51px;
              display: flex;
              flex-basis: 51px;
              flex-shrink: 0;
              img {
                width: 51px;
                height: 51px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
          }
          &:last-child {
            width: 30px;
            display: flex;
            align-items: center;
            flex-basis: 30px;
            flex-shrink: 0;
          }
        }
      }
    }
    .wrapper-add-new-row-item {
      display: flex;
      align-items: center;
      margin: 18px 0px;
      cursor: pointer;
      .wrapper-plus {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        background-color: $color-black-40;
        img {
          width: 11px;
        }
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-16;
        color: $color-black-40;
      }
    }
  }
}
.wrapper-delete-item {
  position: relative;
  cursor: pointer;
  img {
    filter: brightness(0) saturate(100%) invert(62%) sepia(0%) saturate(243%) hue-rotate(67deg) brightness(98%) contrast(94%);
  }
  .dropdown-delete-item {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 34px;
    background-color: $color-black-88;
    border-radius: 16px;
    height: 64px;
    width: 240px;
    padding: 0px 16px;
    z-index: 10;
    img {
      filter: brightness(0) saturate(100%) invert(37%) sepia(58%) saturate(935%) hue-rotate(317deg) brightness(93%) contrast(92%);
      width: 20px;
      margin-right: 16px;
    }
    span {
      font-family: "Nunito SemiBold";
      font-size: $size-18;
      color: $color-red-100 !important;
    }
  }
  &.copy {
    img {
      filter: brightness(0) saturate(100%) invert(39%) sepia(0%) saturate(1%) hue-rotate(308deg) brightness(100%) contrast(95%);
    }
    span {
      color: $color-white !important;
    }
  }
}
