.wrapper-single-item-page {
  .header-page {
    position: relative;
    z-index: 9;
    .custom-btn--circle-outline img {
      filter: none;
    }
  }
  .header-single-page-item {
    position: relative;
    .background-color-single-item {
      height: 165px;
      width: 100%;
      background-image: url("https://www.conciergebelgrade.com/images/products/60/concierge-belgrade-restoran-jerry-slider.jpg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      position: absolute;
      top: -80px;
      left: 0;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, #FFD51D 20%, #1F1F1F 100%);
        opacity: 0.9;
      }
    }
  }
  .content-single-item-page {
    padding: 0px 20px;
    position: relative;
    z-index: 10;
    overflow: auto;
    height: calc(100vh - 200px);
    .wrapper-list-column-single-item {
      height: calc(100vh - 660px);
      overflow: auto;
      .grid {
        grid-gap: 0px 24px;
      }
    }
    .wrapper-image-name-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .wrapper-image-item-single-view {
        display: flex;
        align-items: center;
        .image {
          position: relative;
          img {
            width: 213px;
            height: 213px;
            object-fit: cover;
            border-radius: 50%;
            z-index: 10;
          }
          &:nth-child(2) {
            z-index: 9;
            margin-left: -25px;
          }
          &:nth-child(3) {
            z-index: 8;
            margin-left: -25px;
          }
        }
        .wrapper-add-image-item {
          .add-image-item {
            width: 190px;
            height: 190px;
            border-radius: 50%;
            border: 1px dashed $color-orange-100;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-black-80;
            margin-left: -25px;
            cursor: pointer;
            img {
              width: 37px;
              height: 37px;
              filter: brightness(0) saturate(100%) invert(79%) sepia(58%) saturate(3417%) hue-rotate(331deg) brightness(96%) contrast(98%);
            }
            input {
              display: none;
            }
          }

        }
        .delete-image-item {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-yellow-20;
          border-radius: 50%;
          border: 1px dashed $color-yellow-100;
          z-index: 11;
          img {
            width: 18px;
            height: 18px;
            filter: brightness(0) saturate(100%) invert(79%) sepia(58%) saturate(3417%) hue-rotate(331deg) brightness(96%) contrast(98%);
          }
        }
      }
      span {
        font-family: "Nunito Bold";
        color: $color-white;
        font-size: $size-40;
      }
    }

    .wrapper-likes-and-more-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 28px 0px;
      .wrapper-like-rating-single-item {
        display: flex;
        align-items: center;
        .vertical-line {
          height: 30px;
          width: 1px;
          background-color: $color-black-40;
          margin: 0px 16px;
        }
        .wrapper-likes {
          display: flex;
          align-items: center;
          .custom-btn {
            margin-right: 8px;
            width: 48px;
            height: 48px;
            display: flex;
            flex-basis: 48px;
            flex-shrink: 0;
            img {
              height: 24px;
            }
          }
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-18;
            color: $color-white;
          }
        }
      }
      .custom-btn {
        width: 48px;
        height: 48px;
        margin-right: 0px;
        img {
          height: 24px;
        }
      }
    }
    .column-item-single-view {
      min-height: 50px;
      border-radius: 8px;
      border: 1px solid $color-black-70;
      display: flex;
      flex-direction: column;
      padding: 20px 12px;
      margin-top: 24px;
      .name-column {
        height: 22px;
        border-radius: 20px;
        width: fit-content;
        background-color: $color-black-70;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 6px;
        font-family: "Nunito Regular";
        color: $color-black-20;
        font-size: $size-14;
        margin-bottom: 6px;
      }
      .value-column {
        font-family: "Nunito SemiBold";
        color: $color-white;
        font-size: $size-16;
      }
    }
  }
}
