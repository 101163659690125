.wrapper-verify-account {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 60px 0px;
    .verify-account-page {
        max-width: 480px;
        width: 100%;
        padding: 32px;
        .info-auth-verify-account {
            margin: 32px 0px;
            h1 {
                margin-bottom: 12px;
            }
        }
        .wrapper-verify-code {
            display: flex;
            align-items: center;
            justify-content: space-between;
            input {
                height: 55px;
                width: 60px;
                border: 1px solid $color-black-70;
                background-color: transparent;
                border-radius: 16px;
                font-family: "Nunito Bold";
                font-size: $size-28;
                text-align: center;
                color: $color-white;
                &:focus {
                    border: 1px solid $color-white !important;
                    outline: none;
                    border: 1px solid;
                }
            }
            &.error {
               input {
                   border: 1px solid $color-red-100;
               }
            }
        }
    }
    .footer-verify-account {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
