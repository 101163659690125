.wrapper-auth-pages {
    display: flex;
    align-items: center;
    .wrapper-auth-left {
        width: 50%;
        height: 100vh;
        background-color: $color-black-88;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.add-details {
          justify-content: flex-start;
          .wrapper-login-page {
            overflow: auto;
            height: calc(100vh - 90px);
            position: relative;
            .login-page {
              margin-top: 0px;
              .wrapper-content-auth {
                overflow: inherit;
              }
            }
            .custom-btn {
              position: fixed;
              width: 480px;
              bottom: 20px;
            }
          }
          .info-auth-login {
            h1 {
              margin-bottom: 16px;
            }
          }

        }
        .wrapper-sign-up-company-fixed {
            width: 100%;
            height: 112px;
            background-color: $color-black-85;
            display: flex;
            align-items: center;
            justify-content: center;
            .wrapper-btn-sign-up-company {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-black-70;
                height: 48px;
                max-width: 480px;
                width: 100%;
                border-radius: 40px;
                img {
                    margin-right: 8px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(67deg) brightness(106%) contrast(102%);
                }
                span {
                    font-family: "Nunito Regular";
                    color: $color-white;
                    font-size: $size-18;
                    margin-right: 4px;
                }
                a {
                    font-family: "Nunito Bold";
                    color: $color-white;
                    font-size: $size-18;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .wrapper-auth-right {
        width: 50%;
        background-color: $color-black-primary;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .wrapper-onboarding {
            max-width: 480px;
            width: 100%;
            //padding: 32px;
            .wrapper-image-onboarding {
                img {
                    width: 100%;
                }
                .wrapper-steps-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 32px 0px;
                    .step-btn {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $color-black-40;
                        margin: 0px 6px;
                      cursor: pointer;
                    }
                    .step-btn.active {
                        width: 32px;
                        border-radius: 16px;
                        background-color: $color-primary-purple-100;
                    }
                }
                .wrapper-onboarding-text {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    h1 {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
