.wrapper-login-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-page {
        max-width: 480px;
        width: 100%;
        padding: 32px;
        //overflow: auto;
        margin-top: 60px;
        .wrapper-logo-auth {
            img {

            }
        }
        .wrapper-content-auth {
          overflow: auto;
          height: calc(100vh - 435px);
            .wrapper-social-login {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item-social-login {
                    border: 1px solid $color-black-75;
                    background-color: $color-black-88;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 150px;
                    width: 100%;
                    border-radius: 24px;
                    height: 48px;
                    img {
                        margin-right: 12px;
                    }
                    span {
                        font-family: "Nunito Regular";
                        font-size: $size-14;
                        color: $color-black-40;
                    }
                }
            }
        }
        .info-auth-login {
            margin: 32px 0px;
        }
        &.login {
          margin-top: 0px;
          .wrapper-content-auth {
            height: 100%;
          }
        }
    }
}

