#toast-container .ngx-toastr {
  font-family: "Nunito Regular";
  font-size: $size-18;
  color: $color-white;
  box-shadow: none;
  border-radius: 8px;
  background-size: 40px;
  width: auto;
  padding: 10px 34px 10px 76px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 70px;
  .toast-title {
    text-wrap: nowrap;
  }
  .toast-close-button {
    right: -16px;
    top: -3px;
    font-family: "Nunito Regular";
    font-size: 32px;
    font-weight: 400;
  }
}
#toast-container>.toast-success {
  background-image: url('/assets/icons/notification/icon-success-notification.svg')!important;
  background-color: #479045 !important;
}

#toast-container>.toast-error {
  background-image: url('/assets/icons/notification/icon-error-notification.svg')!important;
  background-color: #D14B4B !important;
}

#toast-container>.toast-warning {
  background-image: url('/assets/icons/notification/icon-warning-notification.svg')!important;
  font-family: "Nunito Regular";
  background-color: #F28D39 !important;
}

#toast-container>.toast-info {
  background-image: url('/assets/icons/notification/icon-info-notification.svg')!important;
  background-color: #175ACA !important;
}
