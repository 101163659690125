.wrapper-dropdown-btn {
  position: relative;
  .content-dropdown-btn {
    position: absolute;
    top: 54px;
    right: 0;
    background-color: $color-black-88;
    border: 1px solid $color-black-70;
    padding: 12px 16px;
    border-radius: 16px;
    width: 245px;
    z-index: 10;
    .item-dropdown {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      img {
        margin-right: 16px;
        width: 20px;
        filter: brightness(0) saturate(100%) invert(42%) sepia(0%) saturate(115%) hue-rotate(285deg) brightness(91%) contrast(88%);
      }
      span {
        font-family: "Nunito SemiBold";
        font-size: $size-18;
        color: $color-white;
      }
      &.delete {
        img {
          filter: brightness(0) saturate(100%) invert(38%) sepia(32%) saturate(1732%) hue-rotate(318deg) brightness(95%) contrast(91%);
        }
        span {
          color: $color-red-100;
        }
      }
    }
  }
}
