.wrapper-my-content {
  position: fixed;
  left: 16px;
  top: 16px;
  height: calc(100vh - 120px);
  width: calc(30% - 32px);
  background-color: $color-black-88;
  .wrapper-header-my-content {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    span {
      font-family: "Nunito SemiBold";
      font-size: $size-22;
      color: $color-white;
    }
  }
  .wrapper-content-my-content {
    height: calc(100vh - 305px);
    overflow: auto;
    padding: 20px 20px 90px 20px;
    .custom-btn {
      &--gray {
        position: absolute;
        left: 20px;
        bottom: 25px;
        width: calc(100% - 40px);
      }
    }
    .tabs-my-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .tab-my-content {
        height: 40px;
        width: 100%;
        border-radius: 24px;
        background-color: $color-black-80;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          font-family: "Nunito Regular";
          color: $color-white;
          font-size: $size-18;
        }
        &.active {
          background-color: $color-black-10;
          span {
            color: $color-black;
          }
        }
        &:first-child {
          margin-right: 16px;
        }
        &:last-child {
          margin-left: 16px;
        }
      }
    }
    .wrapper-tab-lists {
      .wrapper-list-preview {
        .wrapper-info-list {
          align-items: flex-start;
          .time-list {
            font-family: "Nunito Regular";
            color: $color-black-40;
            font-size: $size-16;
          }
          .title-list {
            .label-item {
              font-family: "Nunito Regular";
              color: $color-black-20;
              font-size: $size-14;
            }
          }
        }
      }
    }
    .wrapper-posts-tab {
      padding: 0px;
      height: calc(100vh - 370px);
      overflow: auto;
      .wrapper-card-post {
        padding: 16px;
        .header-card-post {
          .user-card-post {
            img {
              width: 36px;
              height: 36px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 8px;
            }
            .wrapper-user-card-info-post {
              .user-name {
                span {
                  font-size: $size-18;
                }
                img {
                  height: 14px;
                  width: 14px;
                }
              }
              span {
                font-size: $size-14;
              }
            }
          }
        }
        .content-card-post {
          margin-bottom: 0px;
          .description {
            font-size: $size-16;
          }
          .wrapper-image-tagged {
            margin: 12px 0px 0px 0px;
            img {
              height: 90px;
              margin: 0px 4px;
            }
          }
          .wrapper-tagged-list-item {
            margin-top: 12px;
            .wrapper-tags {
              .wrapper-tag {
                margin: 0px 4px;
                height: 28px;
                span {
                  font-size: $size-14;
                }
              }
            }
          }
          .wrapper-toolbar-post {
            margin-top: 12px;
            margin-bottom: 0px;
            .wrapper-toolbar-left {
              span {
                font-size: $size-16;
              }
            }
            .wrapper-toolbar-right {
              .wrapper-like {
                padding: 0px;
                img {
                  width: 14px;
                }
                span {
                  font-size: $size-16;
                }
              }
              .wrapper-share {
                padding: 0px;
                img {
                  width: 14px;
                }
                span {
                  font-size: $size-16;
                }
              }
            }
          }
        }
      }
      .wrapper-write-comment {
        display: none;
      }
    }
    .wrapper-cards-lister {
      padding: 0px;
      height: calc(100vh - 370px);
      overflow: auto;
      .wrapper-card-lister {
        height: 80px;
        padding: 0px;
        border: none;
      }
    }

  }
}
