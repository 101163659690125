.wrapper-search-results {
  .wrapper-custom-tabs {
    margin-top: 32px;
  }
  .wrapper-lists-search-results {
    height: calc(100vh - 390px);
    overflow: auto;
    .wrapper-title-theme {
      display: flex;
      align-items: center;
      margin: 32px 0px 24px 0px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 12px;
      }
      span {
        font-family: "Nunito ExtraBold";
        font-size: $size-24;
      }
    }
  }
  .wrapper-accounts-search-results {
    .wrapper-list-search-accounts {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 485px);
      overflow: auto;
      .title-search-results {
        font-family: "Nunito SemiBold";
        color: $color-black-40;
        font-size: $size-20;
        margin: 32px 0px 8px 0px;
        &:first-child {
          margin-top: 6px;
        }
      }
      .wrapper-card-lister {
        height: 88px;
        flex-basis: 88px;
        flex-shrink: 0;
        display: flex;
      }
    }
  }
  .wrapper-near-you-search-results {
    margin-top: 32px;
    height: calc(100vh - 420px);
    overflow: auto;
    .wrapper-item-list-view {
      margin-bottom: 16px;
      height: 80px;
      background-color: $color-black-80;
      .rating-item-near-you {
        display: flex;
        align-items: center;
        img {
          width: 17px;
          margin-right: 5px;
        }
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
        }
      }
    }
  }
}
.wrapper-user-search {
  height: calc(100vh - 310px);
  margin-top: 32px;
  overflow: auto;
  .wrapper-user-item {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid $color-black-80;
    .wrapper-avatar {
      margin-right: 8px;
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .wrapper-user-info {
      display: flex;
      flex-direction: column;
      .wrapper-username {
        display: flex;
        align-items: center;
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
        }
        img {
          margin-left: 8px;
          filter: brightness(0) saturate(100%) invert(37%) sepia(10%) saturate(2742%) hue-rotate(258deg) brightness(97%) contrast(92%);
        }
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-16;
        color: $color-black-40;
      }
    }
  }
}
