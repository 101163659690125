// ========== Fonts Nunito ===========
// 900
@font-face {
    font-family: "Nunito Black";
    src: url(../fonts/Nunito/static/Nunito-Black.ttf) format("truetype");
}
// 800
@font-face {
    font-family: "Nunito ExtraBold";
    src: url(../fonts/Nunito/static/Nunito-ExtraBold.ttf) format("truetype");
}
// 700
@font-face {
    font-family: "Nunito Bold";
    src: url(../fonts/Nunito/static/Nunito-Bold.ttf) format("truetype");
}
// 600
@font-face {
    font-family: "Nunito SemiBold";
    src: url(../fonts/Nunito/static/Nunito-SemiBold.ttf) format("truetype");
}
// 500
@font-face {
    font-family: "Nunito Medium";
    src: url(../fonts/Nunito/static/Nunito-Medium.ttf) format("truetype");
}
//400
@font-face {
    font-family: "Nunito Regular";
    src: url(../fonts/Nunito/static/Nunito-Regular.ttf) format("truetype");
}
// 300
@font-face {
    font-family: "Nunito Light";
    src: url(../fonts/Nunito/static/Nunito-Light.ttf) format("truetype");
}
