// ========== Body ==========
body {
    margin: 0px;
    min-height: 100vh;
    background-color: $color-black-primary;
}

// ========== Max character length ==========
.length-character {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.length-character-column {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &--2 {
        -webkit-line-clamp: 2;
    }
    &--3 {
        -webkit-line-clamp: 3;
    }
    &--4 {
        -webkit-line-clamp: 4;
    }
    &--5 {
        -webkit-line-clamp: 5;
    }
}
.w-auto {
  width: auto !important;
}
// Flex
.tl-d-flex {
  display: flex !important;
}
.tl-align-center {
  align-items: center !important;
}
.tl-flex-column {
  flex-direction: column !important;
}
.tl-justify-center {
  justify-content: center !important;
}
// Not allowed
.tl-not-allowed {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  .custom-radiobutton {
    cursor: not-allowed !important;
  }
  .wrapper-list-preview {
    cursor: not-allowed !important;
  }
}
// Other
.line-text {
    display: flex;
    flex-direction: row;
    font-size: $size-18;
    font-family: "Nunito Regular";
    color: $color-black-40;
    margin: 32px 0px;
    &:before {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid $color-black-70;
        margin: auto 15px auto 0px;
    }
    &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid $color-black-70;
        margin: auto 0px auto 15px;
    }
}
.line-gray {
  width: 100%;
  height: 1px;
  background-color: $color-black-80;
  margin: 24px 0px;
}
.wrapper-pages {
  height: calc(100vh - 112px);
  width: 100%;
  .wrapper-page {
    padding: 16px;
    .wrapper-home-page {
      margin-left: 30%;
      width: 70%;
      background-color: $color-black-88;
      height: calc(100vh - 120px);
    }
    .wrapper-my-profile-page {
      margin-left: 30%;
      width: 70%;
      background-color: $color-black-88;
      height: calc(100vh - 120px);
    }
    .wrapper-lists-page {
      margin-left: 30%;
      width: 70%;
      background-color: $color-black-88;
      height: calc(100vh - 120px);
    }
    .wrapper-single-item-page {
      margin-left: 30%;
      width: 70%;
      background-color: $color-black-88;
      height: calc(100vh - 120px);
    }
  }
}

// Error
.wrapper-error-message {
  margin-top: 8px;
  align-items: center;
  display: flex;
  //display: none;

  img {
    width: 18px;
    filter: brightness(0) saturate(100%) invert(54%) sepia(87%) saturate(2095%) hue-rotate(320deg) brightness(90%) contrast(88%);
    margin-right: 8px;
  }

  span {
    font-family: "Nunito SemiBold";
    font-size: $size-16;
    color: $color-red-error;
  }
  &.purple {
    img {
      filter: brightness(0) saturate(100%) invert(89%) sepia(72%) saturate(2686%) hue-rotate(273deg) brightness(82%) contrast(82%);
    }
    span {
      color: $color-primary-purple-60;
    }
  }
}
// height 768px
.small-height-resolution {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 510px);
  overflow: auto;
}
