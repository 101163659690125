// Tabs lists - my content
.wrapper-tab-lists {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 370px);
  overflow: auto;
  position: relative;
  .wrapper-list-preview {
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .wrapper-image-list {
      margin-right: 10px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
    .wrapper-info-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .title-list {
        display: flex;
        align-items: center;
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
        }
      }
      .author-list {
        display: flex;
        align-items: center;
        img {
          margin-left: 4px;
          width: 14px;
          filter: brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(456%) hue-rotate(34deg) brightness(91%) contrast(92%);
        }
        span {
          font-family: "Nunito Regular";
          font-size: $size-16;
          color: $color-black-50;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
        }
      }
      .label-item {
        background-color: $color-black-70;
        border-radius: 20px;
        height: 22px;
        padding: 0px 6px;
        font-family: "Nunito Regular";
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-black-20;
        font-size: $size-14;
        margin-left: 6px;
      }

    }
  }
}
// Lists
.wrapper-content-lists-page {
  padding: 20px;
  .title-lists-page {
    font-family: "Nunito SemiBold";
    font-size: $size-32;
    color: $color-white;
    margin-bottom: 22px;
    display: block;
  }
  .wrapper-lists-content {
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    .wrapper-list-item {
      margin-bottom: 16px;
      background-color: $color-black-85;
      height: 80px;
      flex-basis: 80px;
      flex-shrink: 0;
      width: 100%;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .image-name-item {
        display: flex;
        align-items: center;
        img {
          margin-left: 20px;
          margin-right: 10px;
          filter: brightness(0) saturate(100%) invert(66%) sepia(12%) saturate(748%) hue-rotate(259deg) brightness(95%) contrast(91%);
        }
        span {
          font-family: "Nunito Regular";
          font-size: $size-22;
          color: $color-white;
        }
      }
      .icon-right {
        margin-right: 20px;
        transform: rotate(180deg);
        filter: brightness(0) saturate(100%) invert(66%) sepia(12%) saturate(748%) hue-rotate(259deg) brightness(95%) contrast(91%);
      }
    }
  }
}
