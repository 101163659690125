.wrapper-custom-radiobutton {
    display: flex;
    align-items: center;
    flex-direction: column;
    input {
        display: none;
    }
    input:checked ~ .custom-radiobutton {
        background-color: $color-primary-purple-100;
        .circle-radio {
            border: 1px solid $color-white;
            .circle-center {
                display: block;
            }
        }
    }
    label {
        width: 100%;
    }
    .custom-radiobutton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background-color: $color-black-90;
        width: 100%;
        height: 57px;
        //padding: 0px 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        .wrapper-language-info {
            margin-left: 18px;
            display: flex;
            align-items: center;
            img {
                margin-right: 8px;
            }
            span {
                font-family: "Nunito Regular";
                font-size: $size-18;
                color: $color-white;
            }
        }
        .circle-radio {
            width: 16px;
            height: 16px;
            border: 1px solid $color-black-85;
            border-radius: 50%;
            margin-right: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            .circle-center {
                width: 10px;
                height: 10px;
                background-color: $color-white;
                border-radius: 50%;
                display: none;
            }
        }
    }
}
// Radiobutton list
.wrapper-custom-radiobutton-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  input {
    display: none;
  }
  input:checked ~ .custom-radiobutton {
    //background-color: $color-primary-purple-100;
    .circle-radio {
      border: 1px solid $color-white;
      .circle-center {
        display: block;
      }
    }
  }
  label {
    width: 100%;
  }
  .custom-radiobutton {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    cursor: pointer;
    background-color: $color-black-80;
    width: 100%;
    height: 80px;
    //padding: 0px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    .wrapper-language-info {
      display: flex;
      align-items: center;
      width: 100%;
      img {
        margin-right: 8px;
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-white;
      }
    }
    .circle-radio {
      width: 16px;
      height: 16px;
      border: 1px solid $color-black-65;
      border-radius: 50%;
      margin-left: 12px;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle-center {
        width: 10px;
        height: 10px;
        background-color: $color-white;
        border-radius: 50%;
        display: none;
      }
    }
  }
  &.item {

  }
  &.copy-item {

  }
}
// Radiobutton gender
.wrapper-custom-radiobutton-gender {
  display: flex;
  align-items: center;
  input {
    display: none;
  }
  input:checked ~ .custom-radiobutton {
    border: 1px solid $color-white;
    .circle-radio {
      border: 1px solid $color-white;
      .circle-center {
        display: block;
      }
    }
  }
  label {
    width: 100%;
  }
  .custom-radiobutton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: $color-black-88;
    width: 150px;
    height: 49px;
    border: 1px solid $color-black-70;
    //padding: 0px 16px;
    margin-bottom: 16px;
    border-radius: 24px;
    .wrapper-language-info {
      margin-left: 18px;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-white;
      }
    }
    .circle-radio {
      width: 16px;
      height: 16px;
      border: 1px solid $color-black-70;
      border-radius: 50%;
      margin-right: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle-center {
        width: 10px;
        height: 10px;
        background-color: $color-white;
        border-radius: 50%;
        display: none;
      }
    }
  }
}

