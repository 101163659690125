tl-create-list {
  display: flex;
}
.wrapper-page-create-list {
  background-color: $color-black-88;
  width: calc(100% - 32px);
  height: calc(100vh - 112px);
  margin: 16px;
  border-radius: 16px;
  overflow: auto;
  .header-create-list {
    padding: 20px;
  }
  .wrapper-content-create-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    .content-create-list {
      width: 800px;
      h3 {
        margin-bottom: 60px;
      }
      .wrapper-label-gray {
        margin-top: 8px;
        span {
          font-size: $size-18;
          color: $color-black-50;
        }
      }
      .custom-btn {
        &--primary {
          margin-top: 60px;
        }
      }
      .wrapper-visibility {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        span {
          font-family: "Nunito SemiBold";
          font-size: $size-18;
          color: $color-black-40;
        }
        .wrapper-toggle-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          span {
            color: $color-white;
          }
        }
      }
    }
  }
  .wrapper-page-master-item {
    max-width: 800px;
    margin: 0 auto;
    .text-master-item {
      margin: 32px 0px;
      display: block;
      font-family: "Nunito Regular";
      font-size: $size-16;
      color: $color-black-40;
    }
    .wrapper-master-item {
      height: calc(100vh - 360px);
      overflow: auto;
      .wrapper-item-list-view {
        background-color: $color-black-80;
        cursor: pointer;
      }
      .rating-item-near-you {
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
        }
      }
    }
  }
  .wrapper-add-other-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 67px;
    background-color: $color-black-85;
    border: 1px solid $color-primary-purple-100;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    margin: 32px auto;
    cursor: pointer;
    span {
      font-family: "Nunito Bold";
      color: $color-primary-purple-60;
      font-size: $size-16;
    }
    .wrapper-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: $color-primary-purple-100;
      margin-left: 12px;
      img {
        width: 10px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(357deg) brightness(107%) contrast(100%);
      }
    }
  }
}
