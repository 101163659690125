.wrapper-custom-search {
  height: 54px;
  border-radius: 54px;
  width: 100%;
  position: relative;

  .icon-search {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
  }

  input {
    height: 54px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    width: calc(100% - 54px);
    padding-left: 54px;
    font-family: "Nunito Regular";
    color: $color-black-50;
    font-size: $size-18;
    border-radius: 54px;
    border: 1px solid $color-black-70;

    &:focus {
      border: 1px solid $color-white;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-18;
    }
  }

  .wrapper-delete-search {
    position: absolute;
    right: 8px;
    top: 12px;
    background-color: $color-black-80;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    img {
      width: 16px;
    }
  }
}
