// ========== INPUTS ==========
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  position: relative;
  .label-info-input {
    margin-top: 8px;
    font-family: "Nunito Regular";
    font-size: $size-16;
    color: $color-black-40;
  }
  .label-input {
    font-family: "Nunito SemiBold";
    font-size: $size-16;
    color: $color-white;
    margin-bottom: 8px;
  }

  .custom-input {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
    .email-autocomplete {
      display: none;
    }
    input {
      background-color: $color-black-88;
      border: 1px solid $color-black-70;
      height: 100%;
      width: 100%;
      border-radius: 24px;
      padding: 0px 62px 0px 24px;
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-16;
      // Fcous
      &:focus {
        outline: none;
        border: 1px solid $color-white;
      }

      // Placeholder
      &::placeholder {
        font-family: "Nunito Regular";
        color: $color-black-50;
        font-size: $size-16;
      }
    }
    textarea {
      background-color: $color-black-88;
      border: 1px solid $color-black-70;
      height: 100%;
      width: 100%;
      border-radius: 24px;
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-16;
      padding: 14px 24px;
      resize: none;
      // Fcous
      &:focus {
        outline: none;
        border: 1px solid $color-white;
      }

      // Placeholder
      &::placeholder {
        font-family: "Nunito Regular";
        color: $color-black-50;
        font-size: $size-16;
      }
    }

    .icon-input {
      position: absolute;
      width: 22px;
      right: 24px;
      top: 50%;
      transform: translate(0, -50%);
      filter: brightness(0) saturate(100%) invert(74%) sepia(9%) saturate(16%) hue-rotate(314deg) brightness(81%) contrast(91%);
    }
    &.wrapper-email-address-autocomplete {
      position: relative;
      .email-autocomplete {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 150px;
        border-radius: 0px 40px 40px 0px;
        background-color: $color-black-70;
        position: absolute;
        right: 1px;
        top: 1px;
        span {
          font-family: "Nunito Regular";
          font-size: $size-16;
          color: $color-white;
        }
      }
    }
  }
  &.textarea {
    .custom-input {
      height: 184px;
    }
    .label-info-input {
      margin-top: 40px !important;
      display: block;
    }
  }
  .wrapper-phone-number {
    display: flex;
    align-items: center;
    background-color: $color-black-88;
    border: 1px solid $color-black-70;
    border-radius: 24px;
    width: calc(100% - 48px);
    padding: 0px 24px;
    height: 50px;
    position: relative;
    .wrapper-flags {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-flag {
        width: 22px;
        margin-right: 6px;
      }
      .icon-arrow-flags {
        width: 10px;
        transform: rotate(-90deg);
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(329deg) brightness(107%) contrast(102%);
      }
    }
    .wrapper-number-country {
      width: 65px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-black-50;
      }
    }
    input {
      background-color: transparent;
      width: calc(100% - 48px);
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-18;
      outline: none;
      border: none;
      padding-left: 16px;
      // Fcous
      &:focus {
        outline: none;
        border: none;
      }

      // Placeholder
      &::placeholder {
        font-family: "Nunito Regular";
        color: $color-black-50;
        font-size: $size-18;
      }
    }
    .content-dropdown-phone-number {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      background-color: $color-black-75;
      border-radius: 16px;
      height: 150px;
      overflow: auto;
      z-index: 10;
      .item-dropdown-phone-number {
        display: flex;
        align-items: center;
        height: 43px;
        flex-basis: 43px;
        flex-shrink: 0;
        margin: 5px 0px;
        padding: 0px 24px;
        cursor: pointer;
        img {
          width: 22px;
          margin-right: 10px;
        }
        span {
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-white;
        }
      }
    }
    &.open {
      .wrapper-flags {
        .icon-arrow-flags {
          transform: rotate(90deg);
        }
      }
      .content-dropdown-phone-number {
        display: flex;
      }
    }
  }

  .wrapper-selected-country {
    background-color: $color-black-88;
    border: 1px solid $color-black-70;
    height: 50px;
    width: 100%;
    border-radius: 24px;
    display: flex;
    align-items: center;
    img {
      margin-left: 24px;
      width: 22px;
      margin-right: 12px;
    }
    span {
      font-family: "Nunito Regular";
      color: $color-black-50;
      font-size: $size-16;
      // Fcous
      &:focus {
        outline: none;
        border: 1px solid $color-white;
      }

      // Placeholder
      &::placeholder {
        font-family: "Nunito Regular";
        color: $color-black-50;
        font-size: $size-16;
      }
    }
  }
  .wrapper-list-country {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: 150px;
    overflow: auto;
    background-color: $color-black-75;
    border-radius: 16px;
    z-index: 10;
    .item-country {
      display: flex;
      align-items: center;
      height: 43px;
      flex-basis: 43px;
      flex-shrink: 0;
      margin: 5px 0px;
      padding: 0px 24px;
      cursor: pointer;
      img {
        width: 22px;
        margin-right: 10px;
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-white;
      }
    }
  }

  .wrapper-error-message {
    margin-top: 8px;
    align-items: center;
    display: flex;
    //display: none;

    img {
      width: 18px;
      filter: brightness(0) saturate(100%) invert(28%) sepia(26%) saturate(3338%) hue-rotate(332deg) brightness(122%) contrast(76%);
      margin-right: 8px;
    }

    span {
      font-family: "Nunito SemiBold";
      font-size: $size-16;
      color: $color-red-100;
    }
    &.purple {
      img {
        filter: brightness(0) saturate(100%) invert(89%) sepia(72%) saturate(2686%) hue-rotate(273deg) brightness(82%) contrast(82%);
      }
      span {
        color: $color-primary-purple-60;
      }
    }
  }
}

.wrapper-input.error {
  .label-input {
    color: $color-red-100;
  }

  .custom-input {
    input {
      border: 1px solid $color-red-100;
    }
  }

  .wrapper-error-message {
    display: flex;
  }
}
