.wrapper-page-create-post {
  .wrapper-tab-lists {
    margin-top: 20px;
    .wrapper-list-preview {
      width: 100%;
      .wrapper-image-list {
        img {
          width: 56px;
          height: 56px;
        }
      }
      .wrapper-info-list {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .info-list-tag {
          display: flex;
          flex-direction: column;
          .title-list {
            span {

            }
            .label-item {
              background-color: $color-black-70;
              border-radius: 20px;
              height: 22px;
              padding: 0px 6px;
              font-family: "Nunito Regular";
              display: flex;
              align-items: center;
              justify-content: center;
              color: $color-black-20;
              font-size: $size-14;
              margin-left: 6px;
            }
          }
          .time-list {
          }
        }
      }
    }
    .wrapper-custom-radiobutton-list {
      &.item {
        .wrapper-list-preview {
          .wrapper-image-list {
            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
        .wrapper-review-item {
          display: flex;
          align-items: center;
          img {
            margin-right: 6px;
          }
          span {
            font-family: "Nunito Bold";
            color: $color-white;
            font-size: $size-18;
          }
        }
      }
    }
  }
  .wrapper-my-content {
    .wrapper-content-my-content {
      height: auto;
      .tabs-my-content {
        .tab-my-content {
          &:first-child {
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
      }
    }
    .wrapper-custom-search {
      height: 50px;
      input {
        height: 50px;
      }
      .wrapper-delete-search {
        top: 9px;
      }
    }
  }
  .wrapper-create-post-page {
    margin-left: 30%;
    width: 70%;
    background-color: $color-black-88;
    height: calc(100vh - 120px);
    .create-post-page {
      height: calc(100vh - 245px);
      overflow: auto;
      padding: 60px;
      .wrapper-tagged-list-item {
        display: flex;
      }
      .author-create-post {
        display: flex;
        align-items: center;
        .profile-image {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 12px;
        }
        span {
          font-family: "Nunito SemiBold";
          color: $color-white;
          font-size: $size-22;
        }
        .badge {
          margin-left: 6px;
          width: 14px;
          filter: brightness(0) saturate(100%) invert(39%) sepia(15%) saturate(1729%) hue-rotate(258deg) brightness(93%) contrast(96%);
        }
      }
      .wrapper-input {
        &.textarea {
          margin-top: 40px;
          .wrapper-message-description {
            margin-top: 35px;
            .wrapper-error-message {

            }
            .label-info-input {
              margin-top: 16px !important;
            }
          }
        }
      }
      .wrapper-buttons-create-post {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .custom-btn {
          &--outline {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
