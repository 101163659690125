.wrapper-page-group-details {
  background-color: $color-black-88;
  width: calc(100% - 32px);
  height: calc(100vh - 112px);
  margin: 16px;
  border-radius: 16px;
  //overflow: auto;

  .header-group-details {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-black-80;
    span {
      font-family: "Nunito SemiBold";
      color: $color-white;
      font-size: $size-28;
      margin-left: 12px;
    }
  }
  .wrapper-content-group-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 940px;
    height: calc(100vh - 195px);
    overflow: auto;
    margin: 0 auto;
    justify-content: center;
    .wrapper-add-image-group {
      width: 480px;
      min-height: 285px;
      background-color: $color-black-80;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .upload-avatar-group {
        input[type="file"] {
          display: none;
        }
      }
      .wrapper-user-avatar-group-chat {
        margin-bottom: 20px;
        outline: 6px solid rgba(0, 0, 0, 0.2);
      }
      .custom-btn {
        &--gray {
          background-color: $color-black-70;
          width: auto;
          padding: 0px 20px;
        }
      }
    }
    .line-horizontal {
      background-color: $color-black-80;
      height: 1px;
      width: 100%;
      margin: 40px 0px;
    }
    .wrapper-change-name-group {
      width: 480px;
    }
  }
}
