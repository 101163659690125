.home-page {
  height: calc(100vh - 220px);
  overflow: auto;
  padding: 0px 20px;
  .header-home-page {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0px;
    .info-header {
      display: flex;
      flex-direction: column;
      .title {
        font-family: "Nunito SemiBold";
        font-size: $size-28;
        color: $color-white;
      }
      .subtitle {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-black-40;
      }
    }
    .custom-btn {
      width: fit-content;
      height: 38px;
    }
  }
  .wrapper-latest-post {
    display: flex;
    overflow: auto;
    .wrapper-card-post {
      //width: 560px;
      padding: 16px;
      border: 1px solid $color-black-70;
      margin-right: 20px;
      .header-card-post {
        .user-card-post {
          display: flex;
          align-items: center;
          .user-img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 8px;
          }
          .wrapper-user-card-info-post {
            .user-name {
              span {
                font-size: $size-18;
              }
            }
            span {
              font-size: $size-14;
            }
          }
        }
      }
      .content-card-post {
        margin-bottom: 0px;
        .wrapper-image-tagged {
          img {
            height: 116px;
            margin: 0px 6px;
          }
        }
        .description {
          font-size: $size-16;
        }
        .wrapper-tags {
          .wrapper-tag {
            height: 27px;
            margin: 0px 6px;
            img {
              width: 11px;
            }
            span {
              font-size: $size-14;
            }
          }
        }
        .wrapper-toolbar-post {
          margin: 0px;
          height: auto;
          margin-top: 18px;
          .wrapper-toolbar-left {
            span {
              font-size: $size-16;
            }
          }
          .wrapper-toolbar-right {
            .wrapper-like {
              img {
                width: 14px;
              }
              span {
                font-size: $size-16;
              }
            }
            .wrapper-share {
              img {
                width: 14px;
              }
              span {
                font-size: $size-16;
              }
            }
          }
        }
      }
    }
  }
  .wrapper-listers-home-page {
    display: flex;
    align-items: center;
    overflow: auto;
    app-lister {
      margin-right: 32px;
      .wrapper-lister-home {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
          margin-bottom: 8px;
        }
        .name {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
          margin-bottom: 2px;
          display: block;
        }
        .followers {
          font-family: "Nunito Regular";
          font-size: $size-16;
          color: $color-black-40;
        }
      }
    }
  }
  .wrapper-themes-home {
    display: flex;
    align-items: center;
    overflow: auto;
    .wrapper-card-theme {
      margin-right: 20px;
    }
  }
}
