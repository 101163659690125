.wrapper-chat-page {
  padding: 16px;
  height: 100%;
  display: flex;
    .wrapper-chat-all-conversation {
      width: 35%;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      height: 100%;
      background-color: $color-black-88;
      .wrapper-chat-all-conversation-header {
        height: 83px;
        border-bottom: 1px solid $color-black-80;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .back-btn-chat {
          display: flex;
          align-items: center;
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-28;
            color: $color-white;
            margin-left: 12px;
          }
        }
      }
      .wrapper-chat-all-conversation-content {
        border-right: 1px solid $color-black-85;
        .wrapper-custom-search {
          width: calc(100% - 42px);
          margin: 0px 20px;
          padding: 24px 0px 18px 0px;
          .icon-search {
            top: calc(50% + 5px);
          }
          .wrapper-delete-search {
            top: 34px;
          }
        }
        .wrapper-all-conversation-list {
          display: flex;
          flex-direction: column;
          height: calc(100vh - 300px);
          overflow: auto;
          .user-item-list {
            height: 88px;
            border-bottom: 1px solid $color-black-85;
            border-left: 3px solid transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            flex-basis: 88px;
            flex-shrink: 0;
            .user-item-list-left {
              display: flex;
              align-items: center;
              .wrapper-avatar-item {
                margin-right: 8px;
                img {
                  width: 56px;
                  height: 56px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
              .wrapper-name-message {
                display: flex;
                flex-direction: column;
                .wrapper-name {
                  display: flex;
                  align-items: center;
                  span {
                    margin-right: 6px;
                    font-family: "Nunito Bold";
                    font-size: $size-18;
                    color: $color-white;
                    margin-bottom: 4px;
                    max-width: 250px;
                  }
                  img {
                    filter: brightness(0) saturate(100%) invert(38%) sepia(10%) saturate(2694%) hue-rotate(258deg) brightness(93%) contrast(90%);
                  }
                }
                .message {
                  font-family: "Nunito Regular";
                  font-size: $size-16;
                  color: $color-black-40;
                  width: 250px;
                }
              }
            }
            .user-item-list-right {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .item-user-time {
                margin-bottom: 4px;
                span {
                  font-family: "Nunito Bold";
                  font-size: $size-14;
                  color: $color-black-40;
                }
              }
              .unread-message {
                display: none;
                align-items: center;
                justify-content: center;
                width: 21px;
                height: 21px;
                border-radius: 18px;
                background-color: $color-primary-purple-100;
                span {
                  font-family: "Nunito Bold";
                  font-size: $size-14;
                  color: $color-white;
                }
              }
            }
            &.unread {
              .user-item-list-left {
                .wrapper-name-message {
                  .message {
                    color: $color-black-20;
                  }
                }
              }
              .user-item-list-right {
                .item-user-time {
                  span {
                    color: $color-primary-purple-60;
                  }
                }
                .unread-message {
                  display: flex;
                }
              }
            }
            &.active {
              background-color: $color-black-85;
              border-left: 3px solid $color-primary-purple-100;
            }
          }
        }
      }
      .wrapper-add-new-conversation {
        border-right: 1px solid $color-black-85;
        .text-add-new-conversation-group {
          font-family: "Nunito Bold";
          color: $color-white;
          font-size: $size-22;
          padding: 20px 20px 0px 20px;
          display: block;
        }
        .wrapper-custom-search {
          width: calc(100% - 42px);
          margin: 0px 20px;
          padding: 24px 0px 18px 0px;
          .icon-search {
            top: calc(50% + 5px);
          }
          .wrapper-delete-search {
            top: 34px;
          }
        }
        .wrapper-all-people {
          display: flex;
          flex-direction: column;
          height: calc(100vh - 380px);
          overflow: auto;
          .user-item-list {
            height: 88px;
            border-bottom: 1px solid $color-black-85;
            border-left: 3px solid transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            .user-item-list-left {
              display: flex;
              align-items: center;
              .wrapper-avatar-item {
                margin-right: 8px;
                img {
                  width: 56px;
                  height: 56px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
              .wrapper-name-message {
                display: flex;
                flex-direction: column;
                .wrapper-name {
                  display: flex;
                  align-items: center;
                  span {
                    margin-right: 6px;
                    font-family: "Nunito Bold";
                    font-size: $size-18;
                    color: $color-white;
                    margin-bottom: 4px;
                    max-width: 250px;
                  }
                  img {
                    filter: brightness(0) saturate(100%) invert(38%) sepia(10%) saturate(2694%) hue-rotate(258deg) brightness(93%) contrast(90%);
                  }
                }
                .message {
                  font-family: "Nunito Regular";
                  font-size: $size-16;
                  color: $color-black-40;
                  width: 250px;
                }
              }
            }
            .user-item-list-right {
              .checkbox-rectangle {
                width: 24px;
                height: 24px;
                border: 1px solid $color-black-65;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                .checkbox-center {
                  display: none;
                }
              }
            }
            &.unread {
              .user-item-list-left {
                .wrapper-name-message {
                  .message {
                    color: $color-black-20;
                  }
                }
              }
              .user-item-list-right {
                .item-user-time {
                  span {
                    color: $color-primary-purple-60;
                  }
                }
                .unread-message {
                  display: flex;
                }
              }
            }
            &.active {
              background-color: $color-black-85;
              border-left: 3px solid $color-primary-purple-100;
            }
          }
          &.group {
            height: calc(100vh - 430px);
          }
        }
        .wrapper-button-add-chat {
          padding: 20px;
        }
      }
    }
    .wrapper-chat-conversation {
      width: 65%;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      height: 100%;
      background-color: $color-black-88;
      .wrapper-chat-conversation-header {
        height: 83px;
        width: 100%;
        background-color: $color-black-85;
        //padding: 0px 24px;

        .header-chat-conversation {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          padding: 0px 24px;
          .wrapper-chat-conversation-left {
            display: flex;
            align-items: center;
            .avatar-image {
              margin-right: 16px;
              img {
                border-radius: 50%;
                width: 51px;
                height: 51px;
                object-fit: cover;
                filter: unset;
              }
            }
            span {
              font-family: "Nunito Bold";
              font-size: $size-22;
              color: $color-white;
              margin-right: 8px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 435px;
            }
            img {
              filter: brightness(0) saturate(100%) invert(38%) sepia(18%) saturate(1518%) hue-rotate(258deg) brightness(93%) contrast(92%);
            }
          }
          .wrapper-chat-conversation-right {
            display: flex;
            align-items: center;
            .custom-btn {
              &--outline {
                margin-left: 12px;
              }
              &--circle-outline {
                width: 48px;
                height: 48px;
                flex-basis: 48px;
                flex-shrink: 0;
                img {
                  height: 22px;
                }
              }
            }
          }
          .wrapper-custom-search {
            display: none;
          }
        }
        &.open-search {
          .header-chat-conversation {
            background-color: $color-black-88;
            .wrapper-custom-search {
              display: flex;
              height: 50px;
              .wrapper-delete-search {
                top: 9px;
              }
              input {
                height: 50px;
              }
            }
            .wrapper-chat-conversation-left {
              display: none;
            }
            .wrapper-chat-conversation-right {
              display: none;
            }
          }
        }
      }
      .wrapper-chat-conversation-content {
        position: relative;
        .wrapper-loading-media {
          position: absolute;
          bottom: 120px;
          left: 0;
          width: 100%;
          height: 40px;
          background-color: $color-primary-purple-100;
          span {
            color: $color-white;
            font-family: "Nunito SemiBold";
            font-size: $size-14;
            margin-right: 8px;
          }
          .zc-spinner-wrapper-buttons {
            border-radius: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            .zc-spinner {
              position: relative;
              left: 0;
              top: 0;
              transform: translate(0, 0);
            }
          }
        }
        .chat-conversation-content {
          height: calc(100vh - 380px);
          width: calc(100% - 48px);
          background-color: $color-black-88;
          overflow: auto;
          scroll-behavior: smooth;
          padding: 32px 24px;
          position: relative;
          .wrapper-message {
            .message {
              background-color: $color-black-85;
              padding: 12px 46px 12px 16px;
              max-width: 75%;
              margin-bottom: 8px;
              border-radius: 0px 16px 16px 16px;
              width: fit-content;
              position: relative;
              &.media-preview {
                padding: 0px;
                background-color: transparent !important;
                max-width: 100%;
                .wrapper-image-chat {
                  max-width: 100%;
                }
                .wrapper-video-chat {
                  max-width: 100%;
                }
              }
              .text-message {
                span {
                  font-family: "Nunito Regular";
                  font-size: $size-18;
                  color: $color-white;
                }
              }
              .image-message {
                max-width: 300px;
                img {
                  width: 100%;
                  height: auto;
                  cursor: pointer;
                }
              }
              .video-message {
                max-width: 300px;
                .wrapper-video-preview {
                  position: relative;
                  .play-btn {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: $color-primary-purple-20;
                    border-radius: 50%;
                    img {
                      height: 32px;
                      filter: brightness(0) saturate(100%) invert(37%) sepia(19%) saturate(1377%) hue-rotate(258deg) brightness(97%) contrast(93%);
                    }
                  }
                }
              }
              .attachment-message {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                  width: 24px;
                  margin-right: 8px;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7472%) hue-rotate(341deg) brightness(106%) contrast(107%);
                }
                span {
                  font-family: "Nunito Regular";
                  font-size: $size-18;
                  color: $color-white;
                }
              }
              .view-more-message {
                width: 22px;
                height: 22px;
                display: none;
                align-items: center;
                justify-content: center;
                background-color: $color-black-60;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                cursor: pointer;
                img {
                  width: 14px;
                  filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(6317%) hue-rotate(263deg) brightness(108%) contrast(101%);
                  transform: rotate(-90deg);
                }
              }
              .wrapper-emoticons {
                position: absolute;
                bottom: -48px;
                height: 40px;
                left: 0;
                background-color: $color-black-5;
                border-radius: 16px;
                width: 250px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                z-index: 99;
                img {
                  cursor: pointer;
                }
              }
              .wrapper-edit-delete-message {
                position: absolute;
                top: 0;
                height: 40px;
                right: -105px;
                border-radius: 16px;
                width: 100px;
                display: flex;
                align-items: center;
                z-index: 99;
                .edit-delete-message {
                  border: 1px solid $color-black-50;
                  height: 40px;
                  width: 40px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 12px;
                  flex-basis: 40px;
                  flex-shrink: 0;
                  cursor: pointer;
                  img {
                    height: 20px;
                    filter: brightness(0) saturate(100%) invert(50%) sepia(1%) saturate(0%) hue-rotate(49deg) brightness(99%) contrast(93%);
                  }
                  &:hover {
                    border: 1px solid $color-white;
                    img {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(53deg) brightness(102%) contrast(102%);
                    }
                  }
                }
              }
              .wrapper-selected-emoticon {
                position: absolute;
                left: 16px;
                bottom: -18px;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: $color-primary-purple-40;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 99;
                img {
                  width: 19px;
                }
              }
              &:hover {
                .view-more-message {
                  display: flex;
                }
              }
            }
            .time-message {
              font-family: "Nunito SemiBold";
              font-size: $size-14;
              color: $color-black-40;
              max-width: calc(75% + 32px);
              margin-bottom: 24px;
              width: fit-content;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              &.old {
                justify-content: center;
              }
            }
            &.my-message {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .message {
                border-radius: 16px 0px 16px 16px;
                background-color: $color-primary-purple-100;
                .wrapper-emoticons {
                  right: 0;
                  left: auto;
                }
                .wrapper-edit-delete-message {
                  top: 0;
                  left: -125px;
                }
              }
              .time-message {

              }
            }
          }

          &.height-content {
            height: calc(100vh - 259px);
          }
        }
        .chat-conversation-footer {
          height: 120px;
          border-top: 1px solid $color-black-80;
          background-color: $color-black-88;
          display: flex;
          align-items: center;
          width: 100%;
          .wrapper-upload-icons {
            display: flex;
            margin-left: 24px;
            .wrapper-upload-file {
              label {
                cursor: pointer;
                img {
                  margin-right: 12px;
                  cursor: pointer;
                  filter: brightness(0) saturate(100%) invert(81%) sepia(0%) saturate(335%) hue-rotate(299deg) brightness(89%) contrast(92%);
                }
              }
              .upload-custom {
                opacity: 0;
                position: absolute;
                z-index: -1;
              }
            }
          }
          .wrapper-input-chat {
            width: 100%;
            padding: 0px 24px;
            border: 1px solid $color-black-50;
            height: 50px;
            border-radius: 24px;
            input {
              width: 100%;
              margin-top: 1px;
              font-family: "Nunito Regular";
              height: 48px;
              font-size: $size-16;
              color: $color-white;
              background-color: transparent;
              border: none;
              outline: none;
              &::placeholder {
                font-family: "Nunito Regular";
                font-size: $size-16;
                color: $color-black-40;
              }
              &:focus {
                border: none;
                outline: none;
              }
            }
          }
          .wrapper-send-btn {
            margin-left: 12px;
            margin-right: 24px;
            .custom-btn {
              width: 50px;
              height: 50px;
              img {
                height: 22px;
              }
            }
          }
        }
      }
      .wrapper-no-chats-yet {
        height: calc(100vh - 200px);
        //border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        img {
          width: 280px;
        }
        span {
          font-family: "Nunito SemiBold";
          font-size: $size-18;
          color: $color-black-30;
        }
      }
      &.group {
        .wrapper-message {
          .message-group-chat {
            display: flex;
            .avatar-user-group-chat {
              margin-right: 8px;
              img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .message {
              .username-user-group-chat {
                font-family: "Nunito SemiBold";
                font-size: $size-18;
                color: $color-black-40;
              }
              .wrapper-selected-emoticon {
                border-radius: 40px;
                width: auto;
                padding: 0px 2px;
                img {
                  padding: 0px 4px;
                }
              }
            }
          }
          &.my-message {
            .avatar-user-group-chat {
              display: none;
            }
            .message {
              .username-user-group-chat {
                display: none;
              }
            }
          }
        }
      }
    }
}
