.wrapper-single-theme {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);

  .wrapper-content-single-theme {
    margin-top: -80px;
    padding-top: 170px;
    position: relative;
    .overlay1-background-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: $color-black-88;
      height: 170px;
      z-index: 10;
    }
    .overlay2-background-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(0deg, rgba(31, 31, 31, 0.3) 20%, #2b8cab 100%);
      height: 170px;
      z-index: 10;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .title-single-theme {
        color: $color-white;
        font-family: "Nunito Bold";
        font-size: $size-42;
        margin-left: 20px;
        margin-bottom: 20px;
      }
      .label-lists {
        font-family: "Nunito Regular";
        color: $color-white;
        font-size: $size-20;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
    .content-single-theme {
      height: calc(100vh - 315px);
      overflow: auto;
      padding: 0px 20px;
      .custom-btn {
        width: auto;
        margin-bottom: 32px;
      }
      .title-list-theme {
        font-family: "Nunito Bold";
        color: $color-white;
        font-size: $size-32;
        margin-bottom: 20px;
        display: block;
      }
      .wrapper-custom-search {
        margin-bottom: 32px;
      }
      .grid {
        &.lists {
          grid-gap: 8px 32px;
        }
      }
      .wrapper-card-list {
        height: 106px;
        padding: 8px;
        width: auto;
        background-color: $color-black-88;
        cursor: pointer;
        margin-bottom: 0px;
        img.image-list {
          width: 90px;
          height: 90px;
        }
        .wrapper-card-list-info {
          .wrapper-badges {
            .badge {
              height: 22px;
              span {
                font-size: $size-14;
              }
            }
          }
          .name-list {
            font-size: $size-18;
          }
          .time-list {
            font-size: $size-16;
          }
          .author-recommended-list {
            span {
              font-size: $size-16;
            }
            img {
              height: 14px;
            }
          }
        }
        .wrapper-right-element {
          img {
          }
        }
        &:hover {
          background-color: $color-black-80;
        }
      }
    }
  }
}
