.wrapper-counter-box {
  height: 84px;
  width: 450px;
  background-color: $color-yellow-100;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .item-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      font-family: "Nunito Regular";
      color: $color-black;
      font-size: $size-18;
    }
    .number {
      font-family: "Nunito Bold";
      font-size: $size-24;
    }
  }
}
