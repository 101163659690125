.wrapper-card-theme {
  height: 84px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  span {
    font-family: "Nunito Bold";
    color: $color-white;
    font-size: $size-22;
  }
  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 10px;
  }
  .wrapper-theme-info {
    display: flex;
    flex-direction: column;
    .number-lists {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-white;
    }
  }
}
