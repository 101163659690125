// ========== BUTTONS ==========
// Hover button
@mixin button-bg($bg) {
    background: $bg;
    &:hover {
        background:darken($bg,8%);
        transition: all 0.3s ease;
    }
    &:active {
        background:darken($bg,25%);
    }
}
// Buttons styles
.custom-btn {
    text-decoration: none;
    border-radius: 40px;
    text-align: center;
    height: 48px;
    width: 100%;
    min-width: 130px;
    outline: none;
    border: none;
    cursor: pointer;
  position: relative;
    span {
        font-family: "Nunito Bold";
        font-size: $size-18;
    }
    &:focus {
        outline: none;
        border: none;
    }
    &--primary {
        @include button-bg($color-primary-purple-100);
        span {
            color: $color-white;
        }
    }
    &--white {
      @include button-bg($color-white);
      span {
        color: $color-black;
      }
    }
    &--gray {
      background-color: $color-black-80;
      span {
        color: $color-white;
      }
    }
    &--delete {
      @include button-bg($color-red-100);
      span {
        color: $color-white;
      }
    }
    &--yellow {
      @include button-bg($color-yellow-100);
      span {
        color: $color-black;
      }
    }
    &--outline-red {
      border: 1px solid $color-red-100;
      background-color: transparent;
      margin: 0px;
      padding: 0px 24px;
      span {
        color: $color-red-100;
      }
      &:focus {
        border: 1px solid $color-red-100;
      }
    }
    &--outline {
      border: 1px solid $color-primary-purple-80;
      background-color: transparent;
      margin: 0px;
      padding: 0px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: $color-primary-purple-80;
        margin: 0px 6px;
      }
      img {
        width: 18px;
        filter: brightness(0) saturate(100%) invert(52%) sepia(10%) saturate(1336%) hue-rotate(258deg) brightness(95%) contrast(101%);
      }
      &:focus {
        border: 1px solid $color-primary-purple-80;
      }
    }
    &--outline-white {
      border: 1px solid $color-black-50;
      background-color: transparent;
      margin: 0px;
      padding: 0px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto !important;
      margin-left: 16px;
      flex-basis: auto !important;
      span {
        color: $color-white;
        margin: 0px 6px;
      }
      img {
        width: 18px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(44deg) brightness(106%) contrast(108%);
      }
      &:focus {
        border: 1px solid $color-white;
      }
    }
    &--circle-primary {
      width: 40px;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include button-bg($color-primary-purple-100);
      img {
        height: 17px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(180deg) brightness(106%) contrast(100%);
      }
      &.large {
        width: 48px;
        height: 48px;
      }
    }

  &--circle-outline {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border: 1px solid $color-black-70;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex-basis: 40px;
    flex-shrink: 0;

    img {
      filter: brightness(0) saturate(100%) invert(73%) sepia(7%) saturate(0%) hue-rotate(30deg) brightness(97%) contrast(95%);
      height: 14px;
    }

    &:focus {
      border: 1px solid $color-black-70;
    }

    &:hover {
      border: 1px solid $color-white;
    }
  }
  &--circle-fill {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black-25;
    img {
      height: 14px;
    }
    &:focus {
      border: none;
    }
    &:hover {
      background-color: $color-white;
    }
  }
}
.custom-btn:disabled {
    background-color: $color-black-80;
    cursor: not-allowed;
    span {
        color: $color-black-50;
    }
}
