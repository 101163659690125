.wrapper-edit-profile {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);
  .content-edit-profile {
    height: calc(100vh - 200px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .wrapper-change-image {
      background-color: $color-yellow-100;
      height: 270px;
      flex-basis: 270px;
      flex-shrink: 0;
      width: 380px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .preview-image-profile {
        margin-bottom: 24px;
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          border: 2px solid $color-black-65;
        }
      }
      .btn-edit-profile-image {
        label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          //width: 154px;
          height: 48px;
          cursor: pointer;
          span {
            padding: 0px 12px;
          }
        }
        input {
          display: none;
        }
      }
      &.error {
        background-color: $color-red-error;
      }
    }
    .wrapper-list-info-profile {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 760px;
      margin: 60px 0px;
      position: relative;
      .item-list-info-profile {
        height: 75px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-black-70;
        cursor: pointer;
        .list-profile-label {
          width: 128px;
          font-family: "Nunito SemiBold";
          font-size: $size-20;
          color: $color-white;
        }
        .wrapper-value-info-profile {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-20;
            color: $color-black-40;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 570px;
          }
          img {
            filter: brightness(0) saturate(100%) invert(64%) sepia(0%) saturate(2394%) hue-rotate(191deg) brightness(94%) contrast(95%);
            transform: rotate(180deg);
          }
          &.gender {
            span {
              text-transform: capitalize;
            }
          }
        }
      }
      .wrapper-save-btn {
        position: absolute;
        border-bottom: 0;
        left: 0;
        bottom: -130px;
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .wrapper-edit-info-page {
    height: calc(100vh - 200px);
    overflow: auto;
    padding-top: 60px;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .title-edit-page {
      margin-bottom: 12px;
      font-family: "Nunito Bold";
      font-size: $size-36;
      color: $color-white;
    }
    .subtitle-edit-page {
      margin-bottom: 40px;
      font-family: "Nunito Regular";
      font-size: $size-20;
      color: $color-black-40;
    }
    .wrapper-input {
      .label-info-input {
        margin-top: 8px;
        font-family: "Nunito Regular";
        font-size: $size-16;
        color: $color-black-40;
      }
    }
    .buttons-edit-page {
      display: flex;
      align-items: center;
      .custom-btn {
        margin-right: 12px;
      }
      .custom-btn:last-child {
        margin-left: 12px;
      }
    }
    .wrapper-custom-radiobutton-gender {
      label {
        margin-right: 12px;
        width: auto;
      }
    }
  }
}
