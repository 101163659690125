// ========== COLORS ==========
// Primary
$color-primary-purple-100: #974C8B;
$color-primary-purple-80: #AC70A2;
$color-primary-purple-60: #C194B9;
$color-primary-purple-40: #D5B7D1;
$color-primary-purple-30: #E0C9DC;
$color-primary-purple-20: #EADBE8;

// Black
$color-black: #000000;
$color-black-primary: #121212;
$color-black-90: #1A1A1A;
$color-black-88: #1F1F1F;
$color-black-85: #262626;
$color-black-80: #333333;
$color-black-75: #404040;
$color-black-70: #4D4D4D;
$color-black-65: #595959;
$color-black-60: #808080;
$color-black-55: #737373;
$color-black-50: #808080;
$color-black-40: #999999;
$color-black-30: #B3B3B3;
$color-black-25: #BFBFBF;
$color-black-20: #CCCCCC;
$color-black-10: #DEDEDE;
$color-black-7: #E8E8E8;
$color-black-5: #F2F2F2;
// color opacity
$color-black-88-9: rgba(31, 31, 31, 0.9);

// White
$color-white: #ffffff;

// Red
$color-red-100: #D14B4B;
$color-red-error: #DA6060;
$color-dark-red-700: #431818;

// Yellow
$color-yellow-100: #FFD51D;
$color-yellow-20: #FFF7D2;

// Orange
$color-orange: #DAA260;
$color-orange-100: #F28D39;

// Blue
$color-blue-90: #8A95F9;
$color-blue-80: #60C4DA;

// ========== SIZE ==========
$size-9: 0.562rem;
$size-10: 0.625rem;
$size-12: 0.75rem;
$size-13: 0.813rem;
$size-14: 0.875rem;
$size-15: 0.9375rem;
$size-16: 1rem;
$size-17: 1.0625rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-34: 2.125rem;
$size-36: 2.25rem;
$size-40: 2.5rem;
$size-42: 2.625rem;
$size-46: 2.875rem;
$size-48: 3rem;
$size-50: 3.125rem;
$size-55: 3.4375rem;
$size-60: 3.75rem;
$size-64: 4rem;
$size-70: 4.375rem;
$size-90: 5.625rem;
$size-96: 6rem;
$size-100: 6.25rem;
