.wrapper-settings-page {
  .header-settings {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;

    span {
      font-family: "Nunito SemiBold";
      font-size: $size-32;
      color: $color-white;
      padding: 0px 20px;
    }
  }

  .content-settings {
    position: relative;
    height: calc(100vh - 270px);

    .sidebar-settings-left {
      position: absolute;
      left: 0;
      top: 0;
      height: calc(100vh - 375px);
      width: 250px;

      .wrapper-settings-sidebar {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        overflow: auto;
        height: calc(100vh - 395px);

        .wrapper-item-settings-sidebar {
          height: 50px;
          flex-basis: 50px;
          flex-shrink: 0;
          width: calc(100% - 24px);
          border-radius: 8px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          padding: 0px 12px;
          cursor: pointer;

          img {
            margin-right: 8px;
            height: 22px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(215deg) brightness(100%) contrast(101%);
          }

          span {
            font-family: "Nunito Regular";
            font-size: $size-20;
            color: $color-white;
          }

          .wrapper-dropdown-settings-sidebar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .wrapper-dropdown-sidebar {
              display: none;
            }

            .icon-dropdown {
              transform: rotate(-90deg);
            }
          }

          &.active {
            background-color: $color-black-55;
          }

          &.open {
            .wrapper-dropdown-settings-sidebar {
              .wrapper-dropdown-sidebar {
                display: flex;
                flex-direction: column;
              }

              .icon-dropdown {
                transform: rotate(90deg);
              }
            }
          }
        }

        .wrapper-dropdown-sidebar {
          display: flex;
          flex-direction: column;
          padding-left: 24px;
          padding-top: 12px;

          .dropdown-sidebar-item {
            margin-bottom: 32px;
            cursor: pointer;

            span {
              font-family: "Nunito Regular";
              font-size: $size-20;
              color: $color-white;
            }

            &.active {
              span {
                color: $color-primary-purple-80;
              }
            }
          }
        }
      }
    }

    .wrapper-content-settings-right {
      padding-left: 266px;
      width: calc(100% - 266px);
      height: calc(100vh - 375px);

      .content-settings-right {
        height: 100%;
        border-left: 1px solid $color-black-70;
        width: calc(100% - 200px);
        padding: 0px 100px;

        .wrapper-submit-settings {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .title-settings-page {
          font-family: "Nunito SemiBold";
          font-size: $size-28;
          color: $color-white;
          margin-bottom: 24px;
          display: block;
        }

        .subtitle-settings-page {
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-black-30;
          margin-bottom: 24px;
          display: block;
        }

        .wrapper-account-page {
          display: flex;
          flex-direction: column;

          .custom-btn {
            width: fit-content;
            padding: 0px 24px;
          }
        }

        .wrapper-change-password-page {
          display: flex;
          flex-direction: column;

          .custom-btn {
            width: fit-content;
            padding: 0px 24px;
          }
        }

        .wrapper-languages-page {
          position: relative;

          .custom-btn {
            position: absolute;
            bottom: 0;
            width: calc(100% - 12px);
          }

          .label-language-settings {
            font-family: "Nunito SemiBold";
            font-size: $size-18;
            color: $color-black-30;
            margin-bottom: 16px;
            display: block;
          }

          .wrapper-list-languages {
            margin-top: 0px;
            height: calc(100vh - 700px);
          }

          .wrapper-custom-search {
            margin-bottom: 40px;
          }
        }

        .wrapper-subscription-plans-page {
          overflow: auto;
          height: calc(100vh - 400px);
          .label-subscription-plan {
            font-family: "Nunito SemiBold";
            font-size: $size-18;
            color: $color-black-30;
            margin-bottom: 16px;
            display: block;
          }
          .wrapper-subscription-plan {
            .wrapper-plan-card {
              background-color: $color-black-80;
              border-radius: 16px;
              padding: 20px 16px;
              margin-bottom: 12px;
              position: relative;
              .expert-text {
                font-family: "Nunito Regular";
                font-size: $size-14;
                color: $color-black-40;
                margin-top: 12px;
                display: block;
              }
              .title-plan-card {
                font-family: "Nunito Bold";
                font-size: $size-20;
                color: $color-white;
              }

              p {
                font-family: "Nunito Light";
                font-size: $size-16;
                color: $color-black-40;

                span {
                  font-family: "Nunito Bold";
                  color: $color-white;
                }
              }

              .wrapper-feature-plan {
                display: flex;
                flex-direction: column;
                margin-top: 8px;

                .feature-plan {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  .wrapper-feature-circle {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-right: 10px;

                    img {
                      width: 12px;
                      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(99%) contrast(103%);
                    }
                  }

                  span {
                    font-family: "Nunito Regular";
                    font-size: $size-16;
                    color: $color-white;
                  }
                }
              }

              .wrapper-circle-corner {
                position: absolute;
                right: 20px;
                top: 16px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid $color-black-50;

                .circle-corner {
                  width: 12px;
                  height: 12px;
                  margin-left: 2px;
                  margin-top: 2px;
                  border-radius: 50%;
                }
              }

              &.free-plan {
                .wrapper-feature-plan {
                  .feature-plan {
                    .wrapper-feature-circle {
                      background-color: $color-black-40;

                      .feature-circle {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: $color-white;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }

                    .span {
                      color: $color-black-40;
                    }
                  }
                }

                &.selected {
                  background-color: $color-yellow-100;

                  .title-plan-card {
                    color: $color-black;
                  }

                  p {
                    color: $color-black;
                  }

                  .wrapper-feature-plan {
                    .feature-plan {
                      .wrapper-feature-circle {
                        background-color: $color-white;

                        .feature-circle {
                          background-color: $color-yellow-100;
                        }
                      }
                      span {
                        color: $color-black-80;
                      }
                    }
                  }

                  .custom-btn {
                    display: none;
                  }
                  .wrapper-circle-corner {
                    border: 1px solid $color-black;
                    .circle-corner {
                      background-color: $color-black;
                    }
                  }
                }
              }

              &.pay-plan {
                .wrapper-feature-plan {
                  .feature-plan {
                    .wrapper-feature-circle {
                      background-color: $color-primary-purple-100;
                    }
                  }
                }
                &.selected {
                  background-color: $color-primary-purple-100;

                  .title-plan-card {
                    color: $color-white;
                  }

                  p {
                    color: $color-white;
                  }

                  .wrapper-feature-plan {
                    .feature-plan {
                      .wrapper-feature-circle {
                        background-color: $color-white;

                        .feature-circle {
                          background-color: $color-yellow-100;
                        }
                        img {
                          filter: brightness(0) saturate(100%) invert(38%) sepia(11%) saturate(2447%) hue-rotate(258deg) brightness(95%) contrast(93%);
                        }
                      }
                      span {
                        color: $color-white;
                      }
                    }
                  }

                  .custom-btn {
                    background-color: $color-white;
                    span {
                      color: $color-black;
                    }
                  }
                  .wrapper-circle-corner {
                    border: 1px solid $color-white;
                    .circle-corner {
                      background-color: $color-white;
                    }
                  }
                }
              }
            }
          }

          .wrapper-upgrade-to-plan {
            .wrapper-header-upgrade-plan {
              border-top-left-radius: 24px;
              border-top-right-radius: 24px;
              height: 180px;
              width: 100%;
              background-color: $color-primary-purple-100;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              .custom-btn {
                width: fit-content;
                margin-bottom: 14px;
                span {
                  color: $color-primary-purple-100;
                }
              }
              p {
                font-family: "Nunito Bold";
                color: $color-primary-purple-40;
                font-size: $size-16;
                span {
                  color: $color-white;
                  font-size: $size-32;
                }
              }
            }
            .wrapper-content-upgrade-plan {
              border-bottom-left-radius: 24px;
              border-bottom-right-radius: 24px;
              min-height: 220px;
              width: 100%;
              background-color: $color-black-80;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .wrapper-feature-upgrade-plan {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                padding-left: 40px;
                .circle-feature-upgrade-plan {
                  width: 24px;
                  height: 24px;
                  background-color: $color-primary-purple-100;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 12px;
                  img {
                    width: 14px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(349deg) brightness(109%) contrast(104%);
                  }
                }
                span {
                  font-family: "Nunito Regular";
                  font-size: $size-18;
                  color: $color-white;
                }
              }
            }
          }
        }
      }
    }

    .wrapper-content-settings-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 105px;
      border-top: 1px solid $color-black-70;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content-settings-footer-left {
        display: flex;
        align-items: center;

        a {
          padding-left: 20px;
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-black-40;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .content-settings-footer-right {
        span {
          padding-right: 20px;
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-black-40;
        }
      }
    }
  }
}
