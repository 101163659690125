h1 {
    font-family: "Nunito Bold";
    font-size: $size-36;
    color: $color-white;
    margin: 0px;
}
h2 {
    font-family: "Nunito SemiBold";
    font-size: $size-32;
    color: $color-white;
    margin: 0px;
}
h3 {
  font-family: "Nunito SemiBold";
  font-size: $size-28;
  color: $color-white;
  margin: 0px;
}
h4 {}
h5 {}
h6 {
    font-family: "Nunito Regular";
    font-size: $size-20;
    color: $color-black-40;
    margin: 0px;
}
p {
    margin: 0px;
}
.wrapper-link-purple-60 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    a {
        font-family: "Nunito SemiBold";
        color: $color-primary-purple-60;
        font-size: $size-16;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
    }
}
.wrapper-label-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    span {
        font-family: "Nunito Regular";
        color: $color-black-40;
        font-size: $size-18;
        margin-right: 4px;
    }
    a {
        font-family: "Nunito Bold";
        color: $color-primary-purple-100;
        font-size: $size-18;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        span {
          color: $color-primary-purple-100;
        }
    }
}
.wrapper-label-gray {
    span {
        font-family: "Nunito Regular";
        font-size: $size-20;
        color: $color-black-40;
    }
}
.error-message {
    align-items: center;
    display: flex;
    margin-top: 16px;
    img {
        width: 18px;
        filter: brightness(0) saturate(100%) invert(28%) sepia(26%) saturate(3338%) hue-rotate(332deg) brightness(122%) contrast(76%);
        margin-right: 8px;
    }
    span {
        font-family: "Nunito SemiBold";
        font-size: $size-16;
        color: $color-red-100;
    }
    &.purple {
      img {
        filter: brightness(0) saturate(100%) invert(89%) sepia(72%) saturate(2686%) hue-rotate(273deg) brightness(82%) contrast(82%);
      }
      span {
        color: $color-primary-purple-60;
      }
    }
}
