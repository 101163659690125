// Checkbox poeple
.wrapper-custom-checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  input {
    display: none;
  }
  input:checked ~ .user-item-list {
    background-color: $color-black-80;
    .checkbox-rectangle {
      border: 1px solid $color-primary-purple-100 !important;
      background-color: $color-primary-purple-100;
      .checkbox-center {
        display: block !important;
        img {
          width: 16px;
          margin-top: 5px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(136deg) brightness(104%) contrast(101%);
        }
      }
    }
  }
  label {
    width: 100%;
    &:before {
      display: none !important;
    }
  }
}
