.wrapper-successfully-screen {
  background-color: $color-black-88;
  width: calc(100% - 32px);
  height: calc(100vh - 112px);
  margin: 16px;
  border-radius: 16px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper-successfully-screen-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 590px;
    .text-successfully-screen {
      font-family: "Nunito SemiBold";
      font-size: $size-28;
      color: $color-white;
      margin-top: 26px;
      display: block;
    }
    .custom-btn {
      margin-top: 60px;
    }
  }
}
