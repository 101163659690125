.wrapper-cards-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 460px);
}
.wrapper-card-list {
  height: 160px;
  background-color: $color-black-85;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  img.image-list {
    width: 160px;
    height: 160px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 12px;
  }
  .wrapper-card-list-info {
    display: flex;
    flex-direction: column;
    .name-list {
      margin: 8px 0px;
      font-family: "Nunito Bold";
      font-size: $size-22;
      color: $color-white;
    }
    .time-list {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black-40;
    }
    .author-recommended-list {
      display: flex;
      align-items: center;
      span {
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-black-40;
      }
      img {
        margin-left: 4px;
        filter: brightness(0) saturate(100%) invert(73%) sepia(0%) saturate(775%) hue-rotate(28deg) brightness(97%) contrast(98%)
      }
    }
  }
  .wrapper-right-element {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0, -50%);
    img {
      width: 32px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(55deg) brightness(105%) contrast(101%);
    }
  }

}
.custom-preview {
  height: 160px;
  background-color: $color-black-85;
  width: 100%;
  max-width: 960px;
  border-radius: 6px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  img.image-list {
    width: 160px;
    height: 160px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 12px;
  }
  .wrapper-card-list-info {
    display: flex;
    flex-direction: column;
    .name-list {
      margin: 8px 0px;
      font-family: "Nunito Bold";
      font-size: $size-22;
      color: $color-white;
    }
    .time-list {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black-40;
    }
  }
  .wrapper-right-element {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0, -50%);
    img {
      width: 32px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(55deg) brightness(105%) contrast(101%);
    }
  }
}
// Card lister
.wrapper-card-lister {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 112px;
  padding: 0px 20px;
  border-bottom: 1px solid $color-black-80;
  .wrapper-favorite-lister {
    display: flex;
    align-items: center;
    .wrapper-avatar {
      margin-right: 8px;
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .wrapper-user-info {
      display: flex;
      flex-direction: column;
      .wrapper-username {
        display: flex;
        align-items: center;
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
        }
        img {
          margin-left: 8px;
          filter: brightness(0) saturate(100%) invert(37%) sepia(10%) saturate(2742%) hue-rotate(258deg) brightness(97%) contrast(92%);
        }
      }
      span {
        font-family: "Nunito Regular";
        font-size: $size-16;
        color: $color-black-40;
      }
    }
  }
}
