.wrapper-search-page {
  padding: 14px 20px 32px;
  .wrapper-search-first-view {
    margin: 40px 0px;
    height: calc(100vh - 335px);
    overflow: auto;
    .wrapper-title-search-first-view {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img {
        margin-right: 8px;
      }
      span {
        font-family: "Nunito Bold";
        color: $color-white;
        font-size: $size-22;
      }
    }
  }
}
.wrapper-card-list-search {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  img {
    margin-right: 16px;
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 6px;
  }
  .card-list-search-info {
    display: flex;
    flex-direction: column;
    .name {
      font-family: "Nunito Bold";
      font-size: $size-18;
      color: $color-white;
      margin: 8px 0px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 140px;
    }
    .wrapper-author {
      span {
        font-family: "Nunito Regular";
        font-size: $size-16;
        color: $color-black-40;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 135px;
      }
      img {
        margin-right: 0px;
        width: 14px;
        height: 14px;
        filter: brightness(0) saturate(100%) invert(73%) sepia(0%) saturate(775%) hue-rotate(28deg) brightness(97%) contrast(98%);
      }
    }
  }
  &:hover {
    background-color: $color-black-80;
  }
}
