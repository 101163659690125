.header-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0px 20px;
  position: relative;
  z-index: 99;
  .options-header-right {
    display: flex;
    align-items: center;
    .custom-btn {
      margin-left: 12px;
    }
    .wrapper-btn-dropdown {
      position: relative;
      .dropdown-content-btn {
        display: none;
      }
      &.open-dropdown {
        .dropdown-content-btn {
          position: absolute;
          top: 50px;
          right: 0;
          width: 220px;
          height: 142px;
          background-color: $color-black-65;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 50;
          .item-dropdown-btn {
            height: 46px;
            display: flex;
            align-items: center;
            width: calc(100% - 20px);
            margin-left: 20px;
            margin-top: 8px;
            margin-bottom: 8px;
            cursor: pointer;
            img {
              margin-right: 10px;
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(329deg) brightness(107%) contrast(102%);
            }
            span {
              font-family: "Nunito SemiBold";
              font-size: $size-20;
              color: $color-white;
            }
          }
        }
      }
    }
  }
  .wrapper-near-you-btn {
    border: 1px solid $color-black-70;
    height: 40px;
    border-radius: 40px;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .wrapper-icon {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      background-color: $color-primary-purple-100;
      img {
        width: 16px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(188deg) brightness(105%) contrast(101%);
      }
    }
    span {
      font-family: "Nunito SemiBold";
      color: $color-black-30;
      font-size: $size-18;
    }
  }
}
