.wrapper-select-topics-and-artist-page {
    height: 100vh;
    width: 100%;
    background-color: $color-black-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-select-topics-and-artist {
        background-color: $color-black-88;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 600px;
        height: 740px;
        padding: 0px 120px;
        .back-btn {
            position: absolute;
            left: 20px;
            top: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid $color-black-70;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                filter: brightness(0) saturate(100%) invert(71%) sepia(9%) saturate(0%) hue-rotate(20deg) brightness(98%) contrast(98%);
                width: 18px;
            }
        }
        .wrapper-content-select-artist {
            height: 360px;
            overflow: auto;
            width: 100%;
            margin: 40px 0px;
        }
    }
    .wrapper-label-gray {
        margin-top: 24px;
    }
}
