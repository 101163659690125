.wrapper-custom-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  .custom-tab {
    height: 60px;
    border-bottom: 1px solid $color-black-80;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-family: "Nunito Bold";
      font-size: $size-20;
      color: $color-black-40;
    }
    img {
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(62%) sepia(0%) saturate(1084%) hue-rotate(168deg) brightness(99%) contrast(92%);
    }
    &.active {
      border-bottom: 1px solid $color-white;
      span {
        color: $color-white;
      }
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7489%) hue-rotate(63deg) brightness(113%) contrast(96%);
      }
    }
  }
}
.wrapper-custom-tabs-purple {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 24px 0px;
  .custom-tab-purple {
    height: 50px;
    min-width: 58px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black-70;
    margin-right: 12px;
    padding: 0px 20px;
    cursor: pointer;
    span {
      font-family: "Nunito Bold";
      font-size: $size-18;
      color: $color-white;
    }
    img {
      height: 18px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(55deg) brightness(105%) contrast(101%);
    }
    .active {
      display: none;
    }
    .no-active {
      display: block;
    }
    .visibility-text {
      display: none;
    }
    &.active {
      background-color: $color-primary-purple-100;
      .visibility-text {
        display: block;
      }
      .active {
        display: block;
      }
      .no-active {
        display: none;
      }
      img {
        margin-right: 6px;
      }
    }
  }
}
