.wrapper-comments {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $color-black-70;
  margin: 0px 20px;
  .wrapper-comment {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wrapper-comment-left {
      display: flex;
      .avatar-comment {
        margin-right: 12px;
        cursor: pointer;
        img {
          width: 43px;
          height: 43px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .info-comment {
        display: flex;
        flex-direction: column;
        .user-name-comment {
          display: flex;
          align-items: center;
          .name {
            font-family: "Nunito Bold";
            font-size: $size-18;
            color: $color-white;
          }
          img {
            margin: 0px 8px 0px 4px;
            width: 18px;
            filter: brightness(0) saturate(100%) invert(34%) sepia(49%) saturate(589%) hue-rotate(258deg) brightness(96%) contrast(91%);
          }
          .time {
            font-family: "Nunito Regular";
            font-size: $size-16;
            color: $color-black-50;
          }
        }
        .comment {
          margin-top: 8px;
          border-radius: 12px;
          background-color: $color-black-75;
          font-family: "Nunito Regular";
          font-size: $size-16;
          color: $color-white;
          padding: 8px;
        }
      }
    }
    .wrapper-comment-right {
      display: flex;
      align-items: flex-start;
      margin-left: 12px;
      img {
        width: 24px;
        filter: brightness(0) saturate(100%) invert(53%) sepia(0%) saturate(658%) hue-rotate(79deg) brightness(97%) contrast(90%);
        cursor: pointer;
        padding: 0px 8px;
      }
      .wrapper-like {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .like {
          display: none;
        }
        .no-like {
          display: block;
        }
        span {
          font-family: "Nunito SemiBold";
          color: $color-black-50;
          font-size: $size-18;
        }
        &.liked {
          .like {
            display: block;
            filter: brightness(0) saturate(100%) invert(39%) sepia(15%) saturate(1729%) hue-rotate(258deg) brightness(93%) contrast(96%);
          }
          .no-like {
            display: none;
          }
        }
      }
    }
  }
}
