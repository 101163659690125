.wrapper-my-list {
  .header-page {
    background-color: $color-black-88;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .wrapper-header-my-lists {
    height: 85px;
    background-color: $color-black-88;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-my-lists {
      display: flex;
      max-width: 960px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: "Nunito SemiBold";
        font-size: $size-28;
        color: $color-white;
      }
      .custom-btn {
        &--gray {
          width: auto;
          min-width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 18px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(319deg) brightness(105%) contrast(101%);
          }
        }
        &.active {
          background-color: $color-yellow-100;
          img {
            filter: none;
          }
        }
      }
    }
  }
  .wrapper-content-my-lists {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0px 64px 0px;
    .content-my-lists {
      max-width: 960px;
      width: 100%;
      .filter-my-lists {
        display: flex;
        align-items: center;
        .wrapper-custom-search {
          margin-right: 24px;
          input {
            height: 50px;
            background-color: $color-black-88;
          }
          .wrapper-delete-search {
            top: 9px;
          }
        }
        .custom-btn {
          &--outline {
            display: flex;
            align-items: center;
            width: auto;
            height: 50px;
            min-width: 175px;
            justify-content: center;
            span {
              text-wrap: nowrap;
            }
            img {
              margin-left: 8px;
              height: 15px;
              filter: brightness(0) saturate(100%) invert(53%) sepia(12%) saturate(1253%) hue-rotate(258deg) brightness(93%) contrast(93%);
            }
          }
        }
      }
    }
    // Favorite list
    &.favorite-lists {
      .wrapper-custom-search {
        margin-bottom: 32px;
        margin-right: 0px !important;
      }
    }
    &.followed-listers {
      .content-my-lists {
        height: calc(100vh - 320px);
        overflow: auto;
      }
    }
  }
}
