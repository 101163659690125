.wrapper-recently-searched {
  height: calc(100vh - 300px);
  overflow: auto;
  .title-recently-searched {
    font-family: "Nunito SemiBold";
    color: $color-black-40;
    font-size: $size-20;
    margin: 40px 0px 20px 0px;
    display: block;
  }
  .recently-searched-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 18px;
    cursor: pointer;
    margin-bottom: 20px;
    img {
      height: 18px;
      filter: brightness(0) saturate(100%) invert(63%) sepia(2%) saturate(1%) hue-rotate(243deg) brightness(95%) contrast(97%);
      cursor: pointer;
      &:first-child {
        margin-right: 10px;
      }
    }
    span {
      font-family: "Nunito Regular";
      color: $color-white;
      font-size: $size-18;
    }
  }
}
