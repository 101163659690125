.wrapper-my-profile-page {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);
  .content-profile-page {
    height: calc(100vh - 200px);
    overflow: auto;
    .wrapper-info-my-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-top: 35px;
      .wrapper-info-my-profile-left {
        display: flex;
        align-items: center;
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 24px;
        }
        .wrapper-username {
          display: flex;
          flex-direction: column;
          span {
            font-family: "Nunito SemiBold";
            font-size: $size-28;
            color: $color-white;
          }
          .badge {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
              filter: brightness(0) saturate(100%) invert(40%) sepia(6%) saturate(4446%) hue-rotate(257deg) brightness(92%) contrast(94%);
            }
            span {
              font-family: "Nunito Bold";
              font-size: $size-20;
              color: $color-primary-purple-100;
              text-transform: capitalize;
            }
          }
        }
      }
      .wrapper-info-my-profile-right {

      }
    }
    .wrapper-name-desc {
      margin: 35px 0px;
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      .name {
        font-family: "Nunito SemiBold";
        color: $color-white;
        font-size: $size-24;
        margin-bottom: 4px;
      }
      .country {
        font-family: "Nunito Regular";
        color: $color-black-40;
        font-size: $size-18;
        margin-bottom: 24px;
      }
      .desc {
        font-family: "Nunito Regular";
        color: $color-black-40;
        font-size: $size-18;
      }
    }
    .wrapper-btns-my-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      padding: 0px 20px;
      .custom-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          margin-left: 24px;
        }
        img {
          margin-right: 24px;
          width: 18px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(238deg) brightness(101%) contrast(102%);
        }
        &:nth-child(2) {
          margin: 0px 14px;
        }
      }
    }
    .wrapper-tab-lists {
      .wrapper-custom-search {
        margin-bottom: 24px;
      }
      padding: 32px 20px;
    }
  }

}
