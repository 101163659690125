/* Let's get this party started */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    //background-color: rgba(255, 255, 255, 0.35) !important;
    background-color: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px !important;
    border-radius: 10px !important;
    background: #808080 !important;
    border: 2px solid transparent !important;
    background-clip: content-box !important;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #808080 !important;
    border: 2px solid transparent !important;
    background-clip: content-box !important;
}
.wrapper-all-conversation-list::-webkit-scrollbar {
  width: 0px;
}
.add-details .wrapper-login-page::-webkit-scrollbar {
  width: 0px;
}

/* Let's get this party started */
.wrapper-cards-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.wrapper-cards-list::-webkit-scrollbar-track {
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  //background-color: rgba(255, 255, 255, 0.35) !important;
  background-color: transparent !important;
}

/* Handle */
.wrapper-cards-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px !important;
  border-radius: 10px !important;
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

.wrapper-cards-list::-webkit-scrollbar-thumb:window-inactive {
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}
.wrapper-latest-post::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px !important;
  border-radius: 10px !important;
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

.wrapper-latest-post::-webkit-scrollbar-thumb:window-inactive {
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

.wrapper-listers-home-page::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px !important;
  border-radius: 10px !important;
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

.wrapper-listers-home-page::-webkit-scrollbar-thumb:window-inactive {
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}
.wrapper-themes-home::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px !important;
  border-radius: 10px !important;
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

.wrapper-themes-home::-webkit-scrollbar-thumb:window-inactive {
  background: transparent !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}
