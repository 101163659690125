.card-favorite-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-black-85;
  border-radius: 6px;
  height: 112px;
  padding: 0px 20px;
  margin-bottom: 24px;
  .info-favorite-item {
    display: flex;
    align-items: center;
    img {
      width: 83px;
      height: 83px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 12px;
    }
    .item-info {
      display: flex;
      flex-direction: column;
      span {
        font-family: "Nunito Bold";
        font-size: $size-22;
        color: $color-white;
        margin-bottom: 8px;
        display: block;
      }
      .tag-list {
        display: flex;
        align-items: center;
        height: 26px;
        border-radius: 20px;
        background-color: $color-black-70;
        padding: 0px 10px;
        img {
          margin-right: 5px;
          width: 14px;
          height: 14px;
          filter: brightness(0) saturate(100%) invert(84%) sepia(0%) saturate(332%) hue-rotate(103deg) brightness(99%) contrast(90%);
        }
        span {
          font-family: "Nunito Bold";
          font-size: $size-16;
          color: $color-black-20;
          margin-bottom: 0px;
        }
      }
    }
  }
}
