.wrapper-all-themes {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);
  .wrapper-content-all-themes {
    width: auto;
    padding: 20px;
    .wrapper-custom-search {
      margin: 30px 0px;
    }
    .wrapper-list-all-themes {
      height: calc(100vh - 420px);
      overflow: auto;
    }
    .title-all-themes {
      font-family: "Nunito Bold";
      color: $color-white;
      font-size: $size-42;
    }
  }
}
