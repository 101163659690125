.wrapper-insights-page {
  .header-insights {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .preview-days {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 16px;
      cursor: pointer;
      border-radius: 20px;
      background-color: $color-black-10;
      span {
        font-family: "Nunito SemiBold";
        color: $color-black-80;
        font-size: $size-14;
      }
      img {
        margin-left: 8px;
        filter: brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(1752%) hue-rotate(309deg) brightness(97%) contrast(91%);
      }
    }
    .wrapper-week-label {
      span {
        font-family: "Nunito SemiBold";
        color: $color-white;
        font-size: $size-18;
      }
    }
  }
  .content-insights {
    height: calc(100vh - 545px);
    overflow: auto;
    .wrapper-boxes {
      display: flex;
      align-items: center;
      .wrapper-box-insight {
        border-radius: 16px;
        border: 1px solid $color-black-70;
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        background-color: $color-black-85;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
        }
        .wrapper-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-bottom: 4px;
          img {
            width: 20px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(213deg) brightness(104%) contrast(106%);
          }
          &.purple {
            background-color: $color-primary-purple-100;
          }
          &.blue {
            background-color: $color-blue-90;
          }
          &.orange {
            background-color: $color-orange;
          }
          &.blue-light {
            background-color: $color-blue-80;
          }
        }
        .value {
          font-family: "Nunito Bold";
          font-size: $size-22;
          color: $color-white;
        }
        .label {
          font-family: "Nunito Regular";
          font-size: $size-14;
          color: $color-black-40;
        }
        &:hover {
          background-color: $color-black-80;
        }
      }
    }
    .header-lists-insights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: "Nunito SemiBold";
        font-size: $size-24;
        color: $color-white;
      }
      .custom-btn {
        width: fit-content;
        height: 38px;
        background-color: $color-black-70;
        span {
          font-size: $size-18;
        }
      }
    }
    .content-slide-list-insights {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;
      margin: 16px 0px;
      .wrapper-list-insights {
        //margin-right: 16px;
        border-radius: 16px;
      }
    }
    .wrapper-no-list-view-insights {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      img {
        margin-bottom: 8px;
      }
      .label1 {
        font-family: "Nunito Regular";
        color: $color-white;
        font-size: $size-22;
        margin-bottom: 8px;
        max-width: 215px;
        display: block;
      }
      .label2 {
        font-family: "Nunito SemiBold";
        color: $color-black-50;
        font-size: $size-18;
        max-width: 215px;
      }
    }
  }
  .content-single-insights {
    height: calc(100vh - 545px);
    overflow: auto;
    .box-insights {
      padding: 20px;
      border: 1px solid $color-black-70;
      border-radius: 16px;
      margin-top: 12px;
      margin-bottom: 24px;
      background-color: $color-black-85;
      .box-insights-tabs {
        display: flex;
        align-items: center;
        .box-insights-tab {
          height: 28px;
          background-color: $color-black-80;
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 10px;
          width: auto;
          margin-right: 8px;
          cursor: pointer;
          span {
            font-family: "Nunito Medium";
            font-size: $size-13;
            color: $color-white;
          }
          &.active {
            background-color: $color-black-10;
            span {
              font-family: "Nunito SemiBold";
              color: $color-black;
            }
          }
        }
      }
      .wrapper-progressbar {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .header-progressbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name {
            font-family: "Nunito Regular";
            font-size: $size-16;
            color: $color-white;
          }
          .percentage {
            font-family: "Nunito Bold";
            font-size: $size-16;
            color: $color-white;
          }
        }
        .content-progressbar {
          width: 100%;
          height: 6px;
          border-radius: 7px;
          background-color: $color-black-70;
          position: relative;
          margin-top: 10px;
          .overlay-progressbar {
            position: absolute;
            border-radius: 7px;
            height: 6px;
            background-color: $color-primary-purple-100;
            left: 0;
            top: 0;
          }
        }
      }
      .wrapper-box-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .wrapper-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          margin-bottom: 4px;

          img {
            width: 25px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(213deg) brightness(104%) contrast(106%);
          }

          &.purple {
            background-color: $color-primary-purple-100;
          }

          &.blue {
            background-color: $color-blue-90;
          }

          &.orange {
            background-color: $color-orange;
          }

          &.blue-light {
            background-color: $color-blue-80;
          }
        }
        .label1 {
          font-family: "Nunito Bold";
          font-size: $size-24;
          color: $color-white;
        }
        .label2 {
          font-family: "Nunito Regular";
          font-size: $size-18;
          color: $color-black-40;
        }
        .label3 {
          font-family: "Nunito Regular";
          font-size: $size-14;
          color: $color-black-50;
        }
      }
      .no-data-box {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-family: "Nunito Medium";
          font-size: $size-16;
          color: $color-black-50;
        }
      }
      &.themes {
        .wrapper-progressbar {
          margin-top: 0px;
        }
      }
    }
    .title-insights {
      font-family: "Nunito SemiBold";
      font-size: $size-28;
      color: $color-white;
    }
  }
}
.wrapper-list-insights {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-align: center;
  background-color: $color-black-80;
  .image-insights {
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 120px;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .name-list-insights {
    font-family: "Nunito Bold";
    font-size: $size-18;
    color: $color-white;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  p {
    margin-top: 8px;
    display: block;
    font-family: "Nunito Regular";
    font-size: $size-16;
    color: $color-white;
    span {
      color: $color-black-50;
    }
  }
}
