.wrapper-custom-checkbox {
    display: block;
    margin-bottom: 32px;
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 10px;
        width: 4px;
        height: 10px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    input:checked + label:before {
        background-color: #974C8B;
        border: solid #974C8B;
    }
    label {
        position: relative;
        cursor: pointer;
        font-family: "Nunito Regular";
        font-size: $size-18;
        color: $color-white;
        &:before {
            content:'';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid #595959;
            //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            border-radius: 6px;
        }
            span {
                color: $color-primary-purple-100;
            }
    }
}
// Checkbox select topics
.wrapper-custom-checkbox-select-topics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    input {
        display: none;
    }
    input:checked ~ .custom-checkbox-select-topics {
        background-color: $color-yellow-100;
        .check-topic {
            display: flex;
        }
        .wrapper-select-topics-info {
            span {
                color: $color-primary-purple-100;
            }
        }
    }
    label {
        width: fit-content;
    }
    .custom-checkbox-select-topics {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid $color-black-70;
        background-color: transparent;
        width: fit-content;
        height: 47px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 24px;
        padding: 0px 18px;
        .wrapper-select-topics-info {
            display: flex;
            align-items: center;
            span {
                font-family: "Nunito SemiBold";
                font-size: $size-20;
                color: $color-white;
            }
        }
        .check-topic {
            display: none;
            margin-right: 8px;
            img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(8%) saturate(3290%) hue-rotate(258deg) brightness(96%) contrast(90%);
            }
        }
    }
}
// Checkbox select artist
.wrapper-custom-checkbox-select-artist {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    input {
        display: none;
    }
    input:checked ~ .custom-checkbox-select-artist {
        .check-artist {
            display: flex;
        }
        .wrapper-select-artist-info {

        }
    }
    label {
        width: fit-content;
    }
    .custom-checkbox-select-artist {
        position: relative;
        .wrapper-select-artist-info {
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            width: 136px;
            margin-bottom: 10px;
            margin-right: 10px;
            .wrapper-image-info-artist {
                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .text-name {
                font-family: "Nunito Bold";
                font-size: $size-18;
                color: $color-white;
                width: 120px;
            }
            .text-followers {
                font-family: "Nunito Regular";
                font-size: $size-16;
                color: $color-black-40;
            }
        }
        .check-artist {
            display: none;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 18px;
            top: 0;
            outline: 4px solid $color-black-88;
            background-color: $color-primary-purple-100;
            img {
                width: 16px;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(291deg) brightness(99%) contrast(104%)
            }
        }
    }
}
