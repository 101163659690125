// ========== CLEARFIX ==========
@mixin clearfix {
    // For modern browsers
    &:before,
    &:after {
        content:" ";
        display:table;
    }

    &:after {
        clear:both;
    }

    // For IE 6/7 (trigger hasLayout)
    & {
        *zoom:1;
    }
}

%clearfix {
    zoom:1;
    &:before, &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}

#head {
    @extend %clearfix;
    padding: 45px 0 14px; margin: 0 35px 0;
    border-bottom: 1px solid transparent;
}
