.wrapper-upload-image {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .title-upload-image {
    margin-bottom: 12px;
    display: block;
    font-family: "Nunito SemiBold";
    font-size: $size-18;
    color: $color-black-40;
  }
  .upload-image {
    width: 100%;
    height: 320px;
    border-radius: 12px;
    background-color: $color-yellow-100;
    input[type="file"] {
      display: none;
    }

    .custom-file-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      .custom-btn {
        &--white {
          margin-top: 24px;
          width: fit-content;
          padding: 0px 20px;
        }
      }
    }
    .wrapper-preview-image-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 320px;
        height: 220px;
        border-radius: 9px;
        object-fit: cover;
        border: 3px solid rgba(0, 0, 0, 0.2);
      }
      .btns-preview-image-list {
        label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 154px;
          height: 48px;
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        width: 320px;
        margin-top: 12px;
        .custom-btn {
          width: 154px;
          &--white {
            margin-right: 6px;
          }
          &--outline {
            margin-left: 6px;
            span {
              color: $color-black;
            }
          }
        }
      }
    }
    &.error {
      background-color: $color-red-error;
    }
  }
}
