.wrapper-custom-datepicker {
  background-color: $color-black-88;
  border: 1px solid $color-black-70;
  height: 50px;
  width: 100%;
  border-radius: 24px;
  display: flex;
  align-items: center;
  .custom-datepicker {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 24px;
    input {
      width: 100%;
      background-color: transparent;
      height: 50px;
      border: none;
      outline: none;
      font-family: "Nunito Regular";
      color: $color-white;
      font-size: $size-16;
      padding: 0px 24px;
      &:focus {
        border: none;
        outline: none;
      }
      &::placeholder {
        font-family: "Nunito Regular";
        color: $color-black-50;
        font-size: $size-16;
      }
    }
  }
}
.mat-datepicker-content {
  width: 480px;
  background-color: #404040 !important;
  .mat-calendar {
    width: 100% !important;
    height: auto !important;
  }
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #D5B7D1 !important;
  border: none !important;
  color: $color-black-88 !important;
  font-family: "Nunito SemiBold" !important;
}
.mat-calendar-body-cell-content {
  color: $color-white !important;
  font-family: "Nunito Regular" !important;
}
.mat-calendar-body-label {
  color: $color-white !important;
  font-family: "Nunito Regular" !important;
}
.mat-mdc-button {
  color: $color-white !important;
  font-family: "Nunito Regular" !important;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  color: $color-white !important;
  font-family: "Nunito Regular" !important;
}
.mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled), .mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
  color: $color-white !important;
  font-family: "Nunito Regular" !important;
}
.mat-calendar-body-selected {
  background-color: #D5B7D1 !important;
  color: $color-black-88 !important;
  font-family: "Nunito SemiBold" !important;
}
.mat-calendar-table-header th {
  color: $color-black-50 !important;
}
.mat-calendar-arrow {
  fill: $color-white !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(151, 76, 139, 0.2) !important;
}
