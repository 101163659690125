.wrapper-chat-settings {
  padding: 40px;
  .chat-settings-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 40px;
    .my-info-chat {
      display: flex;
      align-items: center;
      .chat-settings-avatar {
        margin-right: 16px;
        img {
          border-radius: 50%;
          width: 120px;
          height: 120px;
          object-fit: cover;
          filter: unset;
        }
      }
      span {
        font-family: "Nunito Bold";
        font-size: $size-28;
        color: $color-white;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }
      img {
        filter: brightness(0) saturate(100%) invert(38%) sepia(18%) saturate(1518%) hue-rotate(258deg) brightness(93%) contrast(92%);
      }
    }
    .btns-chat-settings {
      display: flex;
      flex-direction: column;
      .gray-btn-img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-black-70;
        height: 48px;
        margin: 6px 0px;
        border-radius: 40px;
        padding: 0px 24px;
        width: 300px;
        cursor: pointer;
        span {
          font-family: "Nunito Bold";
          font-size: $size-18;
          color: $color-white;
        }
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(104%) contrast(101%);
        }
        &:hover {
          background-color: $color-black-50;
        }
      }
    }
  }
  .gallery-chat-settings {
    height: calc(100vh - 520px);
    width: 100%;
    margin: 22px 0px;
    overflow: auto;
    .grid {
      grid-gap: 16px 16px;
    }
    .wrapper-media-chat-settings {
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
    }
  }
  .pdfs-chat-settings {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 520px);
    width: 100%;
    margin: 22px 0px;
    overflow: auto;
    .wrapper-pdf-preview {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $color-black-80;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 0px 14px;
      height: 55px;
      flex-basis: 55px;
      flex-shrink: 0;
      cursor: pointer;
      .info-pdf-previe {
        display: flex;
        align-items: center;
        img {
          filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(742%) hue-rotate(259deg) brightness(93%) contrast(93%);
          margin-right: 10px;
          transform: rotate(0deg);
        }
        span {
          font-family: "Nunito SemiBold";
          font-size: $size-20;
          color: $color-white;
        }
      }
      img {
        transform: rotate(180deg);
        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(742%) hue-rotate(259deg) brightness(93%) contrast(93%);
      }
    }
  }
}
