/* Breakpoint Map */
$bp: (
    s: 576px,
    m: 768px,
    l: 992px,
    xl: 1200px,
    xxl: 1400px
);

/*
 * Get a breakpoint from the breakpoint map.
 * Example: min-width: bp(s)
 */
@function bp($breakpoint) {
    @return map-get($bp, $breakpoint);
}

/* Configure Base Grid */
$gutter: 32px;
$grid-columns: 12;

/* Mixin that generates a class per column */
@mixin make-grid-classes($columns: $grid-columns, $bpName: '') {
    $bpSuffix: '';
    @if $bpName != '' {
        $bpSuffix: $bpName + '-';
    }
    @for $i from 1 through $columns {
        .grid__#{$bpSuffix}#{$i} {
            grid-column-end: span $i;
        }
    }
}

.grid {
    display: grid;
    /* 3 Column Grid on mobile */
    grid-template-columns: repeat($grid-columns, minmax(0,1fr));
    grid-gap: $gutter $gutter;
    margin-bottom: $gutter/2;
    &--no-gutter {
        grid-gap: 0;
    }
    .grid {
        margin-bottom: 0;
    }
}

[class*=grid__] {
    grid-column-end: span $grid-columns;
}

@include make-grid-classes($grid-columns);

/* For each breakpoint, create a new media query... */
@each $bpName in map-keys($bp) {
    @media (min-width: bp($bpName)) {
        /*
         * .. then output a list of classes similar to .grid__[breakpoint]-[columns]
         * where [breakpoint] is the name of the breakpoint and [columns ] is the number of
         * columns that element should span.
         */
        @include make-grid-classes($grid-columns,$bpName);
    }
}
