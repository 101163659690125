.wrapper-tags {
  display: flex;
  align-items: center;
  .wrapper-tag {
    margin: 0px 12px;
    height: 40px;
    width: 100%;
    border-radius: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 16px;
      margin-right: 6px;
    }
    span {
      font-family: "Nunito SemiBold";
      font-size: $size-18;
    }
    &.list {
      background-color: $color-primary-purple-100;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(299deg) brightness(101%) contrast(101%);
      }
      span {
        color: $color-white;
      }
    }
    &.item {
      background-color: $color-yellow-100;
      img {
        filter: brightness(0) saturate(100%) invert(34%) sepia(76%) saturate(438%) hue-rotate(258deg) brightness(91%) contrast(86%);
      }
      span {
        color: $color-primary-purple-100;
      }
    }
  }
}
.wrapper-tagged-list-item {
  //display: flex;
  align-items: center;
  margin-top: 20px;
  .tag-post {
    height: 34px;
    border-radius: 24px;
    width: auto;
    padding: 0px 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
      width: 14px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(134deg) brightness(113%) contrast(97%);
    }
    span {
      font-family: "Nunito SemiBold";
      color: $color-white;
      font-size: $size-16;
    }
    .delete-tag-btn {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: $color-black-75;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 6px;
      img {
        width: 14px;
        margin-right: 0px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(134deg) brightness(113%) contrast(97%);
      }
    }
    &.list {
      border: 1px solid $color-primary-purple-100;
    }
    &.item {
      border: 1px solid $color-yellow-100;
    }
  }
}
