.wrapper-circle-gray-icon {
    width: 86px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black-70;
    outline: 14px solid $color-black-60;
    border-radius: 50%;
    img {
        width: 42px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7477%) hue-rotate(6deg) brightness(95%) contrast(110%);
    }
    &.large {
      background-color: $color-black-85;
      outline: 14px solid $color-black-80;
      width: 157px;
      height: 157px;
      img {
        width: 75px;
        filter: brightness(0) saturate(100%) invert(39%) sepia(26%) saturate(1003%) hue-rotate(258deg) brightness(90%) contrast(95%);
      }
    }
}
