.wrapper-single-post-page {
  margin-left: 30%;
  width: 70%;
  background-color: $color-black-88;
  height: calc(100vh - 120px);
  .single-post-page {
    height: calc(100vh - 220px);
    overflow: auto;
    .wrapper-card-post {
      background-color: transparent;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}
