.language-page {
    max-width: 480px;
    width: 100%;
    padding: 32px;
    .info-auth-language {
        margin: 32px 0px;
        h1 {
            margin-bottom: 12px;
        }
    }
}
.wrapper-list-languages {
    height: calc(100vh - 500px);
    width: 100%;
    margin: 32px 0px;
    overflow: auto;
    &.settings {
      .wrapper-custom-radiobutton {
        input:checked ~ .custom-radiobutton {
          background-color: $color-black-90;
        }
      }
    }
  &.view-button-language {
    height: calc(100vh - 750px) !important;
    padding-bottom: 50px;
  }
}
