.wrapper-share-message {
  display: flex;
  flex-direction: column;
  .share-message {
    border-radius: 0px 16px 16px 16px !important;
    padding: 16px !important;
    background-color: $color-black-85 !important;
    width: 100% !important;
    margin-bottom: 8px !important;
    position: relative !important;
    .wrapper-author {
      display: flex !important;
      align-items: center !important;
      margin-bottom: 16px !important;
      img {
        margin-right: 12px !important;
        width: 36px !important;
        height: 36px !important;
        object-fit: cover !important;
        border-radius: 50% !important;
      }
      .wrapper-author-info {
        display: flex !important;
        flex-direction: column !important;
        .name {
          font-family: "Nunito SemiBold" !important;
          font-size: $size-18 !important;
          color: $color-white !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
        .time {
          font-family: "Nunito Regular" !important;
          font-size: $size-14 !important;
          color: $color-black-40 !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
      }
    }
    .wrapper-share-list {
      display: flex !important;
      align-items: flex-start !important;
      justify-content: space-between !important;
      img {
        width: 64px !important;
        height: 64px !important;
        object-fit: cover !important;
        border-radius: 6px !important;
        margin-right: 10px !important;
      }
      .wrapper-info-share-list {
        display: flex !important;
        flex-direction: column !important;
        .name-list {
          font-family: "Nunito Bold" !important;
          font-size: $size-18 !important;
          color: $color-white !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
        .author-list {
          font-family: "Nunito Regular" !important;
          font-size: $size-16 !important;
          color: $color-black-40 !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
      }
      .wrapper-badges {
        .badge {
          span {
            color: $color-black !important;
            text-wrap: nowrap;
          }
        }
      }
    }
    .wrapper-share-item {
      display: flex !important;
      align-items: center !important;
      .number-item {
        font-family: "Nunito SemiBold" !important;
        font-size: $size-16 !important;
        color: $color-white !important;
        margin-right: 10px !important;
      }
      img {
        width: 44px !important;
        height: 44px !important;
        object-fit: cover !important;
        border-radius: 50% !important;
        margin-right: 10px !important;
      }
      .wrapper-info-share-item {
        display: flex !important;
        flex-direction: column !important;
        .name-item {
          font-family: "Nunito Bold" !important;
          font-size: $size-18 !important;
          color: $color-white !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
        .desc-item {
          font-family: "Nunito Regular" !important;
          font-size: $size-14 !important;
          color: $color-black-30 !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 230px !important;
        }
      }
    }
    .wrapper-share-post {
      .wrapper-tags {
        .wrapper-tag {
          margin: 0px 4px;
        }
      }
      .description-post {
        font-family: "Nunito Regular" !important;
        font-size: $size-15 !important;
        color: $color-black-20 !important;
      }
      .wrapper-images {
        margin: 12px 0px !important;
        align-items: center !important;
        display: grid !important;
        grid-template-columns: auto auto !important;
        img {
          width: 96% !important;
          height: 90px !important;
          object-fit: cover !important;
          border-radius: 8px !important;
          margin: 1% !important;
        }
      }
      .wrapper-tags {
        margin-bottom: 8px !important;
      }
    }
    .wrapper-share-review {

    }
    .wrapper-share-profile {
      .wrapper-author {
        margin-bottom: 0px !important;
      }
    }
  }
  .time-message {
    max-width: 75% !important;
    display: flex !important;
    justify-content: flex-start !important;
    margin-bottom: 8px !important;

    span {
      font-family: "Nunito Regular" !important;
      font-size: $size-12 !important;
      color: $color-black-50 !important;
    }
  }
  &.my-share {
    display: flex;
    align-items: flex-end;
    .share-message {
      background-color: $color-primary-purple-100 !important;
      border-radius: 16px 16px 0px 16px !important;
      .wrapper-author {
        .wrapper-author-info {
          .name {
            color: $color-white !important;
          }
          .time {
            color: $color-black-20 !important;
          }
        }
      }
      .wrapper-review {
        border-bottom: 1px solid $color-primary-purple-40 !important;
        span {
          color: $color-white !important;
        }
      }
      .wrapper-share-list {
        .wrapper-info-share-list {
          .name-list {
            color: $color-white !important;
          }
          .author-list {
            color: $color-primary-purple-40;
          }
        }
      }
      .wrapper-share-item {
        .number-item {
          color: $color-white !important;
        }
        .wrapper-info-share-item {
          .name-item {
            color: $color-white !important;
          }
          .desc-item {
            color: $color-primary-purple-30 !important;
          }
        }
      }
      .wrapper-share-post {
        .description-post {
          color: $color-white !important;
        }
        .wrapper-tags {
          .wrapper-tag {
            &.list {
              background-color: $color-white;
              img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(19%) saturate(1351%) hue-rotate(258deg) brightness(97%) contrast(94%);
              }
              span {
                color: $color-primary-purple-100;
              }
            }
          }
        }
      }
    }
  }
}
.wrapper-review {
  display: flex !important;
  flex-direction: column !important;
  padding-bottom: 16px !important;
  border-bottom: 1px solid $color-black-20 !important;
  margin-bottom: 16px !important;
  .wrapper-rating {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 8px !important;
    img {
      margin-right: 5px !important;
    }
  }
  span {
    font-family: "Nunito Regular" !important;
    font-size: $size-15 !important;
    color: $color-white !important;
  }
  &.card {
    border-bottom: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    .wrapper-rating {
      img {
        width: 30px !important;
      }
    }
    span {
      font-size: $size-18 !important;
      color: $color-black-20 !important;
    }
  }
}
