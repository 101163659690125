.wrapper-following-followers {
  padding: 0px 20px;
  height: calc(100vh - 200px);
  overflow: auto;

  .wrapper-custom-search {
    margin: 40px 0px;
  }
  .title-following-followers {
    font-family: "Nunito SemiBold";
    font-size: $size-28;
    color: $color-white;
    margin-bottom: 20px;
    display: block;
  }
  .wrapper-boxes-following-followers {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .wrapper-box-friends {
      height: 78px;
      width: 100%;
      background-color: $color-yellow-100;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      cursor: pointer;
      .box-images {
        display: flex;
        align-items: center;
        margin-right: 16px;
        img {
          width: 54px;
          height: 54px;
          flex-basis: 54px;
          flex-shrink: 0;
          border: 1px solid $color-white;
          object-fit: cover;
          z-index: 10;
          border-radius: 50%;
          &:nth-child(2) {
            margin-left: -22px;
            z-index: 9;
          }
          &:nth-child(3) {
            margin-left: -22px;
            z-index: 8;
          }
        }
      }
      .box-info {
        display: flex;
        flex-direction: column;
        .text1 {
          font-family: "Nunito Bold";
          font-size: $size-20;
          color: $color-black;
        }
        .text2 {
          font-family: "Nunito Regular";
          font-size: $size-16;
          color: $color-black-50;
        }
      }

      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      &.purple {
        background-color: $color-primary-purple-100;
        .box-info {
          .text1 {
            color: $color-white;
          }
          .text2 {
            color: $color-primary-purple-30;
          }
        }
      }
    }
  }
  .wrapper-list-users-ff {
    display: flex;
    flex-direction: column;
    .wrapper-user-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .wrapper-info-user-ff {
        display: flex;
        align-items: center;
        .avatar-user {
          margin-right: 12px;
          img {
            width: 44px;
            height: 44px;
            flex-basis: 44px;
            flex-shrink: 0;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid $color-yellow-100;
            filter: none;
          }
        }
        span {
          font-family: "Nunito SemiBold";
          font-size: $size-20;
          color: $color-white;
          margin-right: 6px;
        }
        img {
          filter: brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(2184%) hue-rotate(258deg) brightness(96%) contrast(87%);
        }
      }
      .custom-btn {
        width: auto;
      }
      &.expert {
        .wrapper-info-user-ff {
          .avatar-user {
            img {
              border: 2px solid $color-primary-purple-100
            }
          }
        }
      }
    }
  }
}
