.wrapper-confirmation-page {
    height: 100vh;
    width: 100%;
    background-color: $color-black-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-confirmation-page {
        background-color: $color-black-88;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 480px;
        min-height: 380px;
        padding: 60px 120px;

        .wrapper-circle-gray-icon {
            margin-bottom: 40px;
        }
        .wrapper-header-confirmation-page {
            text-align: center;
            margin-bottom: 40px;
            h1 {
                margin-bottom: 12px;
            }
            h6 {
                padding: 0px 60px;
            }
        }
        .wrapper-input {
            width: 100%;
        }
    }
}
