.wrapper-added-to-your-list {
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  background-color: $color-yellow-100;
  border-radius: 12px;
  padding: 16px;
  width: 430px;
  .header-added-to-your-list {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      width: 82px;
      height: 82px;
      border-radius: 6px;
      object-fit: cover;
    }
    span {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black;
    }
  }
}
