.custom-toggle-button {
  input[type="checkbox"] {
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }
  label {
    display: block;
    width: 85px;
    height: 34px;
    background-color: $color-black-10;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    &::after {
      content: "";
      width: 26px;
      height: 26px;
      background-color: $color-white;
      position: absolute;
      border-radius: 50%;
      top: 4px;
      left: 4px;
      transition: 0.5s;
    }
  }
  input:checked + label:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  input:checked + label {
    background-color: $color-primary-purple-100;
  }
  label:active:after {
    width: 85px;
  }
}
