.wrapper-preview-media {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $color-black-88-9;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-preview {
    max-height: 75vh;
    max-width: 95vw;
    width: auto;
  }
  video {
    max-height: 75vh;
    max-width: 95vw;
    width: auto;
    height: 75vh;
  }
  .btns-preview-media {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    img {
      height: 20px;
      filter: brightness(0) saturate(100%) invert(54%) sepia(0%) saturate(59%) hue-rotate(62deg) brightness(93%) contrast(92%);
    }
    .custom-btn {
      margin-left: 8px;
      border: 1px solid $color-black-60;
      &:hover {
        border: 1px solid $color-white;
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(105deg) brightness(104%) contrast(100%);
        }
      }
    }
  }
}
