.wrapper-footer-page {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: $color-black-88;
  display: flex;
  align-items: center;
  justify-content: center;

  .tab-footer {
    margin: 0px 20px;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    img {
      width: 22px;
      margin-right: 10px;
      filter: brightness(0) saturate(100%) invert(84%) sepia(0%) saturate(226%) hue-rotate(59deg) brightness(86%) contrast(91%);
    }

    span {
      font-family: "Nunito SemiBold";
      font-size: $size-18;
      color: $color-black-30;
    }
    .custom-btn {
      img {
        height: 19px;
        filter: brightness(0) saturate(100%) invert(82%) sepia(71%) saturate(1282%) hue-rotate(338deg) brightness(102%) contrast(100%);
        margin-right: 0px;
      }
    }
    .wrapper-tab-profile {
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
        filter: none;
      }
      span {
        font-family: "Nunito SemiBold";
        font-size: $size-18;
        color: $color-black-30;
      }
    }
    &.active {
      border-bottom: 2px solid $color-white;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(114deg) brightness(103%) contrast(101%);
      }
      span {
        color: $color-white;
      }
      .wrapper-tab-profile {
        img {
          filter: none;
        }
      }
    }
  }
}
