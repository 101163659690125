.wrapper-user-avatar-group-chat {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-basis: 51px;
  flex-shrink: 0;
  margin-right: 16px;
  .images-grid-chat {
    display: grid;
    gap: 0;

    .number-more-people {
      display: none;
    }

    .image {
      width: 51px;
      height: 51px;
      overflow: hidden;
    }

    .image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: none !important;
    }
  }

  .images-grid-chat.people-more {
    grid-template-columns: repeat(2, 25px);
    grid-template-rows: repeat(2, 25px);
    position: relative;

    .number-more-people {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: "Nunito Bold";
        font-size: $size-10;
        color: $color-black;
      }
    }
  }

  .images-grid-chat.people-4 {
    grid-template-columns: repeat(2, 25px);
    grid-template-rows: repeat(2, 25px);
  }

  .images-grid-chat.people-3 {
    grid-template-columns: repeat(2, 25px);
    grid-template-rows: repeat(1, 25px);
  }

  .images-grid-chat.people-2 {
    grid-template-columns: repeat(2, 25px);
    grid-template-rows: repeat(1, 25px);
  }
  &.settings-avatar {
    width: 120px;
    height: 120px;
    flex-basis: 120px;
    .images-grid-chat {
      .image {
        width: 120px;
        height: 120px;
      }
    }
    .images-grid-chat.people-more {
      grid-template-columns: repeat(2, 60px);
      grid-template-rows: repeat(2, 60px);
      position: relative;

      .number-more-people {
        width: 60px;
        height: 60px;
      }
    }
    .images-grid-chat.people-4 {
      grid-template-columns: repeat(2, 60px);
      grid-template-rows: repeat(2, 60px);
    }

    .images-grid-chat.people-3 {
      grid-template-columns: repeat(2, 60px);
      grid-template-rows: repeat(1, 60px);
    }

    .images-grid-chat.people-2 {
      grid-template-columns: repeat(2, 60px);
      grid-template-rows: repeat(1, 60px);
    }
  }
  &.chat-details {
    width: 150px;
    height: 150px;
    flex-basis: 150px;
    .images-grid-chat {
      .image {
        width: 150px;
        height: 150px;
        img {
          border: 2px solid rgba(0, 0, 0, 0.8);
        }
      }
    }
    .images-grid-chat.people-more {
      grid-template-columns: repeat(2, 75px);
      grid-template-rows: repeat(2, 75px);
      position: relative;

      .number-more-people {
        width: 75px;
        height: 75px;
      }
    }
    .images-grid-chat.people-4 {
      grid-template-columns: repeat(2, 75px);
      grid-template-rows: repeat(2, 75px);
    }

    .images-grid-chat.people-3 {
      grid-template-columns: repeat(2, 75px);
      grid-template-rows: repeat(1, 75px);
    }

    .images-grid-chat.people-2 {
      grid-template-columns: repeat(2, 75px);
      grid-template-rows: repeat(1, 75px);
    }
  }
}
