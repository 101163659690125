.wrapper-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  .wrapper-modal-content {
    max-width: 510px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: $color-black-90;
    text-align: center;
    align-items: center;
    .wrapper-modal-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      img {
        width: 40px;
      }
    }
    .modal-title {
      font-family: "Nunito SemiBold";
      font-size: $size-28;
      color: $color-white;
      margin-bottom: 12px;
    }
    .modal-description {
      font-family: "Nunito Regular";
      font-size: $size-18;
      color: $color-black-30;
    }
    .modal-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 32px;
      .custom-btn:first-child {
        margin-right: 8px;
      }
      .custom-btn:last-child {
        margin-left: 8px;
      }
    }
    &.delete {
      .wrapper-modal-image {
        background-color: $color-dark-red-700;
        img {
          filter: brightness(0) saturate(100%) invert(39%) sepia(10%) saturate(3554%) hue-rotate(312deg) brightness(100%) contrast(98%);
        }
      }
    }
    &.primary {
      .wrapper-modal-image {
        background-color: $color-primary-purple-100;
        img {
          filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%) hue-rotate(61deg) brightness(105%) contrast(105%);
        }
      }
    }
    &.add-new {
      .wrapper-input {
        width: 100%;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 0px;
      }
      .wrapper-modal-image {
        background-color: $color-primary-purple-100;
        img {
          filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%) hue-rotate(61deg) brightness(105%) contrast(105%);
        }
      }
    }
    &.cancel-subscription {
      padding: 30px;
    }
  }
  .wrapper-modal-content-share {
    max-width: 510px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: $color-black-90;
    text-align: center;
    align-items: center;
    .modal-header-share {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span {
        font-family: "Nunito Bold";
        font-size: $size-24;
        color: $color-white;
      }
      img {
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(90%) sepia(100%) saturate(0%) hue-rotate(31deg) brightness(107%) contrast(101%);
      }
    }
    .modal-content-share {
      .wrapper-custom-search {
        margin-top: 24px;
        img {
          left: 0px;
        }
        input {
          border-radius: 0px;
          border: none;
          border-bottom: 1px solid $color-black-70;
          padding-left: 30px;
          width: calc(100% - 30px);
        }
      }
      .wrapper-all-users-share {
        width: 100%;
        margin-top: 24px;
        .wrapper-custom-checkbox-select-artist {
          .custom-checkbox-select-artist {
            .wrapper-select-artist-info {
              width: 64px;
              margin: 0px 10px;
              margin-bottom: 20px;
              .text-name {
                width: 64px;
              }
              .wrapper-image-info-artist {
                img {
                  width: 64px;
                  height: 64px;
                }
              }
            }
            .check-artist {
              width: 16px;
              height: 16px;
              right: 10px;
              img {
                width: 12px;
              }
            }
          }
        }
      }
      .wrapper-share-social-media {
        .title-social-media {
          width: 100%;
          text-align: center;
          span {
            font-family: "Nunito Bold";
            font-size: $size-16;
            color: $color-black-40;
          }
        }
        .wrapper-social-media {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          overflow: auto;
          margin-top: 12px;
          width: 510px;
          .wrapper-share-btn {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0px 16px;
            cursor: pointer;
            .share-btn {
              width: 58px;
              height: 58px;
              background-color: $color-primary-purple-100;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 8px;
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(269deg) brightness(106%) contrast(101%);
              }
            }
            span {
              font-family: "Nunito SemiBold";
              font-size: $size-16;
              color: $color-white;
            }
          }
          .social-media {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px 16px;
            cursor: pointer;
            img {
              width: 58px;
              height: 58px;
              border-radius: 12px;
              margin-bottom: 8px;
            }
            span {
              font-family: "Nunito SemiBold";
              font-size: $size-16;
              color: $color-white;
            }
          }
        }
      }
    }
    .modal-buttons-share {
      display: flex;
      align-items: center;
      width: 100%;
      .custom-btn {
        &--outline {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          img {
            margin-right: 6px;
            filter: brightness(0) saturate(100%) invert(37%) sepia(12%) saturate(2282%) hue-rotate(258deg) brightness(94%) contrast(88%);
          }
        }
      }
    }
  }
  &.copy-to-my-list {
    .wrapper-modal-content {
      .wrapper-header-modal-copy-to-my-list {
        text-align: center;
        position: relative;
        width: 100%;
        height: 42px;
        margin-bottom: 32px;
        span {
          font-family: "Nunito SemiBold";
          font-size: $size-28;
          color: $color-white;
        }
        .custom-btn {
          position: absolute;
          right: 0;
          top: 0;
          img {

          }
        }
      }
      .wrapper-content-modal-copy-to-my-list {
        width: 100%;
        padding: 32px 0px 12px 0px;
        .wrapper-tab-lists {
          height: auto;
          max-height: calc(100vh - 600px);
          .wrapper-custom-radiobutton-list {
            .custom-radiobutton {
              .circle-radio {
                margin-right: 20px;
                flex-basis: 16px;
                flex-shrink: 0;
              }
            }
          }
          .wrapper-list-preview {
            .wrapper-info-list {
              align-items: flex-start;
              .label-item {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}
// Modal filter
.wrapper-modal-filter {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  .wrapper-content-modal-filter {
    max-width: 500px;
    width: 100%;
    height: calc(100vh - 64px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: $color-black-90;
    text-align: center;
    align-items: center;
    margin-right: 16px;
    .header-modal-filter {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-bottom: 24px;
      span {
        font-family: "Nunito Bold";
        color: $color-white;
        font-size: $size-28;
      }
    }
    .content-modal-filter {
      text-align: left;
      .label-filter {
        color: $color-black-20;
        font-size: $size-16;
        font-family: "Nunito SemiBold";
        margin-bottom: 16px;
        display: block;
      }
      width: 100%;
      .wrapper-content-select-artist {
        height: calc(100vh - 325px);
        overflow: auto;
        padding-bottom: 20px;
      }
      .buttons-filter-modal {
        display: flex;
        align-items: center;
        .custom-btn {
          &:first-child {
            margin-right: 12px;
          }
          &--primary {
            border: 1px solid #AC70A2;
            margin: 0px;
            padding: 0px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .custom-btn {
        &--outline-white {
          width: fit-content !important;
          height: 30px;
          min-width: 50px;
          margin-left: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
