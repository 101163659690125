.wrapper-badges {
  display: flex;
  align-items: center;
  .badge {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0px 12px;
    width: fit-content;
    margin-right: 8px;
    span {
      font-family: "Nunito Regular";
      font-size: $size-16;
      color: $color-black-20;
    }
    img {
      width: 14px !important;
      height: 14px !important;
      display: flex !important;
      flex-basis: 14px !important;
      flex-shrink: 0 !important;
      margin-right: 4px !important;
    }
  }
  .badge.yellow {
    background-color: $color-yellow-100;
    span {
      color: $color-black;
    }
  }
  .badge.gray {
    background-color: $color-black-70;
    span {
      color: $color-black-20;
    }
    img {
      filter: brightness(0) saturate(100%) invert(85%) sepia(0%) saturate(298%) hue-rotate(107deg) brightness(96%) contrast(94%);
    }
  }
}
