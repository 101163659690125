.wrapper-create-review {
  width: 800px;
  margin: 0 auto;
  padding-bottom: 32px;
  .author-create-review {
    display: flex;
    align-items: center;
    .profile-image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 12px;
    }
    span {
      font-family: "Nunito SemiBold";
      color: $color-white;
      font-size: $size-22;
    }
    .badge {
      margin-left: 6px;
      width: 14px;
      filter: brightness(0) saturate(100%) invert(39%) sepia(15%) saturate(1729%) hue-rotate(258deg) brightness(93%) contrast(96%);
    }
  }
  .label-create-review {
    margin: 32px 0px 8px 0px;
    display: block;
    font-family: "Nunito SemiBold";
    color: $color-white;
    font-size: $size-18;
  }
  .wrapper-add-rating {
    display: flex;
    align-items: center;
    margin: 32px 0px;
    img {
      width: 40px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .wrapper-message-info {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(39%) sepia(10%) saturate(2757%) hue-rotate(258deg) brightness(93%) contrast(92%);
    }
    span {
      font-family: "Nunito SemiBold";
      color: $color-white;
      font-size: $size-16;
    }
  }
}
